import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import toolkit from "../../utils";

/**
 * @component Login
 * @props - { object } -  config
 */
const LoginForm = (props) => {
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});

  /**
  * @function handleFormSubmit
  * @param e { obj } - form event
  * @return void
  */
  const handleFormSubmit = e => {
    e.preventDefault();
    axios({
      method: "post",
      url: `${toolkit.setReactAppApi("login")}`,
      headers: { "content-type": "application/json" },
      data: formData,
      withCredentials: true // Set credentials to true
    })
      .then(result => {
        if (result.status === 200) {
          setError(false);
          window.location.reload(true);
        } else if (result.status === 403) {
          setError(true);
        }
      })
      .catch(error => {
        setError(true)
      })
  };
  /**
  * @function handleChange
  * @param e { obj } - change event
  * @param field { string } - namve of the field
  * @return void
  */
  const handleChange = (e, field) => {
    let value = e.target.value;
    let domain = window.location.href.includes("localhost:3000") ? ".localhost" : ".gmodesign.pl";
    setFormData({
      ...formData,
      [field]: value,
      domain,

    });
  };

  const { errorMessage, fieldsConfig } = props.config;
  return (
    <div className="login-form">
      <div className="login-form__container">
        <div>
          <form action="#">
            {fieldsConfig &&
              fieldsConfig.map(field => {
                return (
                  <React.Fragment key={field.id}>
                    {field.type !== "textarea" ? (
                      <React.Fragment>
                        <label>{field.label}</label>
                        <input
                          type={field.type}
                          className={field.className}
                          placeholder={field.placeholder}
                          value={field.name}
                          onChange={e => handleChange(e, field.fieldName)}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <label>{field.label}</label>
                        <textarea className={field.className} placeholder={field.placeholder} onChange={e => handleChange(e, field.fieldName)} value={field.name} />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
            <input type="submit" onClick={e => handleFormSubmit(e)} value="Zaloguj" />
            <div>
              {error && <div className="error">{errorMessage}</div>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
//propTypes for the component
LoginForm.propTypes = {
  config: PropTypes.object.isRequired
};