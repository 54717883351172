import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import toolkit from "../../utils";
import './editable-list.css';


/**
 * @component EditableList
 * @props - string -  config
 */
const EditableList = (props) => {
    const [dataToShow, setDataToShow] = useState([]);
    const [showThumbnails, setShowThumbnails] = useState(false);
    const [editDelivery, setEditDelivery] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    const [showLabels, setShowLabels] = useState(true);
    const [showVariantsData, setShowVariantsData] = useState(false);
    const [editVariantsData, setEditVariantsData] = useState(false);
    const [type] = useState(props.type);
    const [editedItemPrice, setEditedItemPrice] = useState('');
    const [editedItemDelivery, setEditedItemDelivery] = useState('0');
    const [editedItemName, setEditedItemName] = useState('');
    const [editInline, setEditInline] = useState(false);
    const [editedItemId, setEditedItemId] = useState(null);
    const [deliveryData, setDeliveryData] = useState([]);
    const [newItem, setNewItem] = useState({ label: '', img: '', price: '', value_change: '', delivery_change: '' });
    const urlParams = new URLSearchParams(window.location.search);
    const optionId = urlParams.get('id');

    const handleAddNewClick = (event) => {
        event.preventDefault(); // Prevent the default link behavior
        const newItem = {
            label: '',
            img: '',
            price: '',
            value_change: '',
            delivery_change: ''
        };
        setDataToShow((prevData) => [...prevData, newItem]);
        setEditedItemId(newItem.id);
    };
    
    const handleEditClick = (item, event) => {
        event.preventDefault(); // Prevent the default link behavior
        setEditedItemId(item.id);
        setEditedItemName(item.label);
        setEditedItemDelivery(item.delivery_change.toString());
        setEditedItemPrice(item.value_change);
    };
    

    const getDeliveryPriceLabel = (selectedDeliveryId) => {
        const selectedOption = deliveryData.find(option => option.id === parseInt(selectedDeliveryId, 10));
        return selectedOption ? selectedOption.label : 0;
    };

    const fetchData = async () => {
        // eslint-disable-next-line default-case
        switch (type) {
            case "product":
                let shopData = await toolkit.getShopProducts();
                const mappedArray = shopData.map(item => ({
                    id: item.id,
                    label: item.title,
                    img: `${item.thumb}`,
                    url: `/edit?type=${type}&id=${item.id}`
                }));
                setShowThumbnails(true)
                setDataToShow(mappedArray);
                break
            case "collection":
                let collectionData = await toolkit.getCollections();
                const collectionsArray = Object.entries(collectionData).map(([id, label]) => ({
                    id: parseInt(id, 10),
                    label,
                    url: `/edit?type=${type}&id=${id}`
                }));
                setDataToShow(collectionsArray);
                setEditInline(true)
                let newCollection = {
                    label: "",
                };
                setNewItem(newCollection);
                break
            case "category":
                let categoryData = await toolkit.getCategories();
                const categoriesArray = Object.entries(categoryData).map(([id, label]) => ({
                    id: parseInt(id, 10),
                    label,
                    url: `/edit?type=${type}&id=${id}`
                }));
                setDataToShow(categoriesArray);
                setEditInline(true);
                let newCategory = {
                    label: "",
                };
                setNewItem(newCategory);
                break
            case "delivery":
                let deliveryData = await toolkit.getDelivery();
                const deliveryArray = deliveryData.map(item => ({
                    id: item.id,
                    label: item.label,
                    price: item.price,
                    url: `/edit?type=${type}&id=${item.id}`
                }));
                setShowPrice(true)
                setDataToShow(deliveryArray);
                let newDelivery = {
                    label: "",
                    price: "",
                };
                setNewItem(newDelivery);
                setEditDelivery(true);
                break
            case "options":
                let optionsData = await toolkit.getOptions();
                const optionsArray = optionsData.map(item => ({
                    id: item.id,
                    label: item.name,
                    url: `/edit?type=${type}&id=${item.id}`
                }));
                const filteredOptionsArray = optionsArray.filter(item => item.id !== 1);
                setDataToShow(filteredOptionsArray);
                break
            case "wood":
                let woodData = await toolkit.getVariants("1");
                const woodArray = woodData.map(item => ({
                    id: item.id,
                    label: item.label,
                    url: `/edit?type=${type}&id=${item.id}`,
                    img: `${item.path}`,
                }));
                setShowThumbnails(true);
                setDataToShow(woodArray);
                break
            case "gallery":
                let galleryData = await toolkit.getPortfolioImages();
                const galleryArray = galleryData.map(item => ({
                    id: item.id,
                    label: item.label || "",
                    url: `/edit?type=${type}&id=${item.id}`,
                    img: `${item.thumbs_path}`,
                }));
                setShowThumbnails(true);
                setShowLabels(false);
                setDataToShow(galleryArray);
                break
            case "variants":
                let variantsData
                if (optionId === "new") {
                    variantsData = await toolkit.getVariants();
                }
                else {
                    variantsData = await toolkit.getVariants(optionId);
                }
                let deliveryDataForAllVariants = await toolkit.getDelivery();
                let options = await toolkit.getOptions();
                const variantsArray = variantsData.map(item => {
                    const foundDeliveryData = deliveryDataForAllVariants.find(element => element.id === parseInt(item.delivery_change));
                    const deliveryChangeValue = foundDeliveryData ? foundDeliveryData.id : 0;
                    const foundObject = options.find(obj => obj.id === parseInt(item.option_id));
                    let name = foundObject ? foundObject.name : null;
                    return {
                        id: item.id,
                        label: item.label || "",
                        delivery_change: deliveryChangeValue,
                        value_change: item.value_change,
                        option: name
                    };
                });
                setDeliveryData(deliveryDataForAllVariants)
                setDataToShow(variantsArray);
                setShowVariantsData(true);
                setEditVariantsData(true);
                break
        }
    };

    const removeProduct = async (item, event) => {
        event.preventDefault();
        if ((type === "collection" || type === "category" || type === "options") && item.id === 1) {
            alert("Tego elementu nie można usunąć, ponieważ jest powiązany z filtrami, kategoriami lub próbkami drewna.");
        } else {
            const shouldDelete = window.confirm("Czy na pewno chcesz usunąć ten element?");
            if (shouldDelete) {
                try {
                    let deleteResult = await toolkit.deleteItem(type, item.id);
                    if (deleteResult) {
                        setDataToShow((prevData) => prevData.filter((prevItem) => prevItem.id !== item.id));
                    } else {
                        alert("Produkt nie został usunięty, spróbuj ponownie");
                    }
                } catch (error) {
                    console.error("Error removing product:", error);
                }
            }
        }
    };

    const handleSaveClick = async (event) => {
        event.preventDefault(); 
        if (typeof editedItemPrice != Number){
            alert("Zmiana ceny powinna być wartością numeryczną");
            return
        }
        if (editedItemId === null) {
            // Adding a new item
            try {
                // Save the new item
                const newItemId = await toolkit.update(type, {
                    label: editedItemName,
                    price: editedItemPrice,
                    delivery_change: editedItemDelivery,
                    value_change: editedItemPrice,
                    optionId: optionId
                });
                // Update the id of the newly added item
                setEditedItemId(newItemId);
                // Reload the page after data has been saved
                window.location.reload();
            } catch (error) {
                console.error("Error adding item:", error);
                alert("Wystąpił błąd podczas dodawania nowego elementu. Spróbuj ponownie.");
            }
        } else {
            // Editing an existing item
            // Find the original item data
            const originalItem = dataToShow.find((item) => item.id === editedItemId);
            // Check if values are modified
            const isLabelModified = editedItemName !== '' && editedItemName !== originalItem.label;
            const isPriceModified = editedItemPrice !== '' && editedItemPrice !== originalItem.value_change;
            const isDeliveryModified = editedItemDelivery !== '' && editedItemDelivery !== originalItem.delivery_change;
            // Check if any value is modified
            if (!isLabelModified && !isPriceModified && !isDeliveryModified) {
                // No values are modified, reset the edit state
                setEditedItemId(null);
                setEditedItemPrice('');
                setEditedItemDelivery('10');
                setEditedItemName('');
                return;
            }
    
            try {
                // Update the existing item
                if (isLabelModified || isPriceModified || isDeliveryModified) {
                    await toolkit.updateItem(type, editedItemId, {
                        label: isLabelModified ? editedItemName : originalItem.label,
                        price: isPriceModified ? editedItemPrice : originalItem.value_change,
                        delivery_change: isDeliveryModified ? editedItemDelivery : originalItem.delivery_change,
                        value_change: isPriceModified || isDeliveryModified ? editedItemPrice : originalItem.value_change,
                        optionId: optionId
                    });
                }
                // Update the dataToShow array
                setDataToShow((prevData) =>
                    prevData.map((prevItem) =>
                        prevItem.id === editedItemId
                            ? {
                                ...prevItem,
                                label: isLabelModified ? editedItemName : prevItem.label,
                                price: isPriceModified ? editedItemPrice : prevItem.price,
                                delivery_change: isDeliveryModified ? editedItemDelivery : prevItem.delivery_change,
                                value_change: isPriceModified ? editedItemPrice : prevItem.value_change,
                            }
                            : prevItem
                    )
                );
                setEditedItemId(null);
                setEditedItemPrice('');
                setEditedItemDelivery('10');
                setEditedItemName('');
                // Reload the page after data has been saved
                window.location.reload();
            } catch (error) {
                console.error("Error updating item:", error);
                alert("Wystąpił błąd podczas zapisywania zmian. Spróbuj ponownie.");
            }
        }
    };
    useEffect(() => {
        fetchData();
        if (deliveryData.length > 0) {
            setEditedItemDelivery(deliveryData[0].id.toString());
        }
    }, [setEditedItemId, setEditedItemPrice, setEditedItemDelivery, setEditedItemName]);

    return (
        <div className="editable-list">
            <div className="add-new-entry">
                {(showVariantsData || editInline || editDelivery) && <button className="add-new-item" onClick={(e) => handleAddNewClick(e)}>
                    DODAJ
                </button>}
                {(!showVariantsData && !editInline && !editDelivery) && <a className="add-new-item" href={`/edit?type=${type}&id=new`}>
                    DODAJ
                </a>}
            </div>

            <form className="list-form" action="#">
                <table className="editable-table table-responsive" cellSpacing="0">
                    <thead>
                        <tr>
                            {showVariantsData && <th className="option"> Przypisane do opcji </th>}
                            {showThumbnails && <th className="thumbnail"><span className="screen-reader-text">Miniatura</span></th>}
                            {showLabels && <th className="name"> Nazwa </th>}
                            {showPrice && <th className="name"> Cena </th>}
                            {showVariantsData && <th className="name"> Zmiana ceny </th>}
                            {showVariantsData && <th className="name"> Przypisana dostawa </th>}
                            {(editVariantsData || editDelivery || editInline) && <th className="edit"> Edytuj </th>}
                            <th className="remove"> Usuń </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataToShow.map((item, index) => (
                            <tr key={index} className="row_item">
                                {showThumbnails && (
                                    <td className="thumbnail">
                                        <a href={item.url} >
                                            <img decoding="async" width="58" height="72" src={item.img} alt="" />
                                        </a>
                                    </td>
                                )}
                                {editInline && !editVariantsData && (
                                    <>
                                        <td className="name" data-title="Nazwa">
                                            {editedItemId === item.id ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={editedItemName}
                                                        placeholder={item.label}
                                                        onChange={(e) => setEditedItemName(e.target.value)}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <span>{item.label}</span>
                                                </div>
                                            )}
                                        </td>
                                        <td className="edit" data-title="Edytuj">
                                            {editedItemId === item.id ? (
                                                <button className="editable-button" onClick={(e) => handleSaveClick(e)}>
                                                    Zapisz
                                                </button>
                                            ) : (
                                                <button
                                                    className="editable-button"
                                                    onClick={(e) => handleEditClick(item, e)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                >    {editedItemId === null ? "Dodaj" : "Edytuj"}
                                             
                                                </button>
                                            )}
                                        </td>
                                    </>)}
                                {showLabels && !editInline && !editVariantsData && !editDelivery && <td className="name" data-title="Nazwa"><a href={item.url}>{item.label || "pusta nazwa - kliknij by zmienić"}</a></td>}
                                {editDelivery && showPrice && (
                                    <>
                                        <td className="name" data-title="Nazwa">
                                            {editedItemId === item.id ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={editedItemName}
                                                        placeholder={item.label}
                                                        onChange={(e) => setEditedItemName(e.target.value)}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <span>{item.label}</span>
                                                </div>
                                            )}
                                        </td>
                                        <td className="name" data-title="Cena">{editedItemId === item.id ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    value={editedItemPrice}
                                                    placeholder={item.price}
                                                    onChange={(e) => setEditedItemPrice(e.target.value)}
                                                />
                                            </div>
                                        ) : (
                                            <div>
                                                <span>{item.price}</span>
                                            </div>
                                        )}</td>
                                    </>)

                                }
                                {showVariantsData && (
                                    <>
                                        <td className="option" data-title="Opcja">
                                            <div>
                                                <span>{item.option}</span>
                                            </div>

                                        </td>
                                        <td className="name" data-title="Nazwa">
                                            {editedItemId === item.id ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={editedItemName}
                                                        placeholder={item.label}
                                                        onChange={(e) => setEditedItemName(e.target.value)}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <span>{item.label}</span>
                                                </div>
                                            )}
                                        </td>
                                        <td className="name" data-title="Zmiana ceny">
                                            {editedItemId === item.id ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={editedItemPrice}
                                                        placeholder={item.value_change}
                                                        onChange={(e) => setEditedItemPrice(e.target.value)}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <span>{item.value_change}</span>
                                                </div>
                                            )}
                                        </td>
                                        <td className="name" data-title="Cena dostawy">
                                            {editedItemId === item.id ? (
                                                <div>
                                                    {/* Replace the input with a select element */}
                                                    <select
                                                        className="list-variants-select"
                                                        value={editedItemDelivery}
                                                        onChange={(e) => setEditedItemDelivery(e.target.value || editedItemDelivery)}
                                                    >
                                                        <option key="" value="">
                                                                  
                                                            </option>
                                                        {deliveryData.map(option => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.label} - {`${option.price} PLN`}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            ) : (
                                                <div>
                                                    {/* Display the selected option label and price */}
                                                    <span>{item.delivery_change === 0 ? "nie ustawiona" : `${getDeliveryPriceLabel(item.delivery_change)}`}</span>
                                                </div>
                                            )}
                                        </td>
                                    </>
                                )}
                                {(editVariantsData || editDelivery) && (
                                    <td className="edit" data-title="Edytuj">
                                        {editedItemId === item.id ? (
                                            <button className="editable-button" onClick={(e) => handleSaveClick(e)}>
                                                Zapisz
                                            </button>
                                        ) : (
                                            <button
                                                className="editable-button"
                                                onClick={(e) => handleEditClick(item, e)}
                                                // Add the following line to prevent the default link behavior
                                                onMouseDown={(e) => e.preventDefault()}
                                            >
                                                Edytuj
                                            </button>
                                        )}
                                    </td>
                                )}
                                <td className="remove" data-title="Usuń">
                                    <button className="list-remove-button" onClick={(e) => removeProduct(item, e)}> X </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </form>
        </div>
    );
};



export default EditableList;
//propTypes for the component
EditableList.propTypes = {
    type: PropTypes.string.isRequired
};
