import React from 'react';
import toolkit from "../utils";
import ImageUploader from "./image-uploader";

const ImageGalleryWithUploader = ({ images, setImages, newImages, setNewImages, thumbnailImage, setThumbnailImage, type }) => {
    const handleImageUpload = async (imageUrls) => {
        const { imagePath, thumbsPath } = imageUrls;
        // Always add the new image
        setNewImages((prevImages) => [
            ...prevImages,
            { imagePath, thumbsPath }
        ]);
        if (type === "wood") {
            // For "wood" type, update images, newImages
            setImages([{ imagePath, thumbsPath }]);
            setNewImages([{ imagePath, thumbsPath }]);
        };
        setThumbnailImage(thumbsPath);
    };

    const removeImage = async (index, image) => {
        switch (type) {
            case "product":
                const updatedImages = [...newImages];
                updatedImages.splice(index, 1); // Extract the removed image
                setNewImages(updatedImages);

                // Delete image from the server
                await toolkit.deleteImage("product", image.id);
                // Update the main images state to trigger a re-render
                const remainingImages = images.filter((img) => img.id !== image.id);
                setImages(remainingImages);

                // If the removed image was the thumbnail, set the thumbnail to null
                if (thumbnailImage && thumbnailImage === image) {
                    setThumbnailImage(null);
                }
                break
        }
    };
    const allImages = type !== "wood" ? [...images, ...newImages] : images;

    const handleThumbnailSelection = (image) => {
        if (type === "product" && thumbnailImage !== image.thumbs_path) {
            setThumbnailImage(image.thumbs_path);
        }
    };

    return (
        <div className="image-gallery-with-uploader">
            {allImages.length > 0 && (
                <div className="flex-gallery">
                    <div className="thumbnails product-images edit-thumbnails">
                        {allImages.map((image, index) => (
                            <div
                                key={index}
                                className={`thumbnail-container ${thumbnailImage === image.thumbs_path ? 'selected' : ''}`}
                                onClick={() => handleThumbnailSelection(image)}
                            >
                                <img
                                    src={image.thumbs_path || image.thumbsPath}
                                    alt={`Thumbnail ${index + 1}`}
                                    className="thumbnail"
                                />
                                {type !== "gallery" && <button
                                    className="remove-button edit-remove"
                                    type="button"
                                    onClick={() => removeImage(index, image)}
                                >
                                    Usuń
                                </button>}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <ImageUploader onUpload={handleImageUpload} type={type} />
            <p className='warning-note'>Jeśli wgrałeś obrazek! Przed kliknięciem "Zapisz" - POCZEKAJ az pojawi się miniatura.</p>
            <p className='warning-note'>Postaraj się nie wrzucać obrazków, które będą miały ponad 650px w Y</p>
        </div>
    );
};

export default ImageGalleryWithUploader;
