import React from 'react';
import "./regular-pages.css";

function OrderRulesPage() {

    return (
        <div className="order-content">
            <h1> Zamówienia i dostawa</h1>
                <p><strong>Zakupy w GMO Meble: </strong></p>
                <p> 1. Wybierz opcje produktu, które Cię interesują oraz ilość”. </p>
                <p> 2. Kliknij <a href="/cart"> Ikonę koszyka</a> aby wypełnić formularz i wysłać zamówienie do GMO Meble.</p> 
                <p> 3. Wypełnij formularz, który pojawi się na ekranie. </p>
                <p> 4. Kliknij "Wyślij zamówienie" i oczekuj na kontakt z naszej strony. Skontaktujemy się z Tobą w celu ustalnia kosztów dostawy i terminu dostawy </p>
                &nbsp;

                <p><strong>Dlaczego nie widzę ostatecznych kosztów dostawy?</strong></p>
                <p>Produkty wysyłamy za pomocą firm kurierskich lub dostarczamy własnym transportem.</p>
                <p>W zależności od wielkości zamówienia koszty mogą się różnić. Dlatego przed podaniem ostatecznej ceny skontaktujemy się z Tobą.</p>
                &nbsp;

                <p><strong>Dlaczego nie mogę przelać kwoty za zamówienie od razu? </strong></p>
                <p>Dane do przelewu oraz ostateczna kwota zamówienia zostanie wysłana w mailu po ustaleniu szczegółów dostawy. </p>
                &nbsp;

                <p><strong>Jaki jest termin realizacji mebli? </strong></p>
                <p>Meble realizujemy do 21 dni roboczych. Ostateczny termin realizacji zostanie podany mailowo. </p>
                &nbsp;

                <p><strong>Jaki jest termin dostawy? </strong></p>
                <p>Meble wysłane na palecie dostarczane są w ciągu 1-3 dni (roboczych) od daty wysyłki.&nbsp;</p>
                <p>Dostawę realizujemy również własnym transportem. Cena do uzgodnienia.</p>
                <p>UWAGA! Zwykle łączymy dostawy do kilku klientów i obniżamy koszt dostawy.</p>

                <p><strong>Czy wysyłacie produkty poza granice Polski? </strong></p>
                <p>Tak, w celu uzgodnienia kosztów i terminu dostawy, prosimy o kontakt mailowy.&nbsp;</p>

        </div >


    );
}

export default OrderRulesPage;