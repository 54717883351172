import React from "react";
import "./how-to-buy.css";

function HowToBuy() {


    return (
        <div className="how-to-buy">
            <section id="how-to-buy-section">
                <h1 className="how-to-buy-header">Zakup w 4 krokach</h1>
                <div className="row how-to-buy-row">
                    <div className="col-buy">
                        <h2> 1. Wybierz</h2>
                        <p>Odwieź nasz sklep i wybierz mebel</p>
                        <p>Wybierz wymiary, rodzaj drewna oraz wykończenie</p>
                    </div>
                    <div className="col-buy">
                        <h2> 2. Złóż zamówienie</h2>
                        <p> Na tym etapie nie ma obowiązku zapłaty </p>
                        <p> Złożenie zamówienia to tylko zapytanie o ofertę</p>
                    </div>
                    <div className="col-buy">
                        <h2> 3. Czekaj na potwierdzenie </h2>
                        <p>W możliwie najkrótszym czasie otrzymasz dokładnie wyliczoną ofertę wraz z kosztami transpotu, szacowanym terminem realizacji oraz danymi do przelewu zaliczki. </p>
                    </div>
                    <div className="col-buy">
                        <h2> 4. Opłać i czekaj na dostawę </h2>
                        <p> Po otrzymaniu zaliczki przyjmujemy Twoje zamówienie do realizacji. </p>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default HowToBuy;