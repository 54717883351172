import React from "react";

const rulesConsentCheckbox = ({ id, checked, onChange, red }) => {
    const checkboxStyle = red ? { color: "red" } : {};
    return (
        <div className="rules-checkbox" id="rules">
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={id} style={checkboxStyle}>
                Dokonując zakupu akceptuję{" "}
                <a href="/regulamin/"> Regulamin sklepu * </a>
            </label>
        </div>
    );
};

export default rulesConsentCheckbox;
