import React from 'react';
import "./regular-pages.css";

function HowToCare() {

    return (
        <div className="how-to-care">
            <h1>Jak pielęgnować drewno</h1>
            <h2><strong>Drewno wymaga opieki.&nbsp;Dlatego dobrze jest pamiętać o podstawowych zasadach pielęgnacji:</strong></h2>
            <ul>
                <li>Nie stawiaj bezpośrednio na powierzchni mebla gorących, ciepłych, ani mokrych przedmiotów.</li>
                <li>Nie używaj szmatek z mikrowłókniny. Działają jak paper ścierny.</li>
                <li>Nie sporządzaj notatek na kartce położonej bezpośrednio na blacie mebla.</li>
                <li>Jeśli możesz, zadbaj o stałą wilgotność powietrza w pomieszczeniach. To pomoże nie tylko Twoim meblom, ale da komfort również mieszkańcom.</li>
                <li>Unikaj umieszczania drewnianych mebli w pobliżu grzejników i innych źródeł ciepła.</li>
            </ul>
            <h2><strong>Pielęgnacja drewna woskowanego:</strong></h2>
            <ul>
                <li>Powłokę woskową na drewnie należy odnowić co najmniej razy na rok. Nie dotyczy to lamp, je można woskować ale nie trzeba.</li>
                <li>Przed woskowaniem prosze odkurzyć meble, by w szczelinach drewna nie zalegał kurz.</li>
                <li>Należy używać wyłącznie wosku bezbarwnego do drewna, inne zmienią kolor mebla.</li>
                <li>Przed woskowaniem proszę okleić folią malarską, elementy aluminiowe, szklane i ściany jeśli meble są do nich przytwierdzone.</li>
                <li>Nie stosuj wosków silikonowych, detergentów ani spray-ów przeciw osiadaniu kurzu.</li>
                <li>W przypadku zabrudzenia powierzchni żywnością natychmiast zbierz zabrudzenie miękkim, papierowym ręcznikiem.</li>
            </ul>
            <h2><strong>Mycie szkła na meblach:</strong></h2>
            <p>Ważne! Myjąc szyby należy uważać aby środek do mycia szyb nie stykał się z drewnem.&nbsp;Nie wolno spryskiwać szyb, płynem do mycia, kiedy te znajdują się na lampie, lub meblach. Środek do mycia szyb nanosimy na szmatkę, którą przecieramy szkło. Dobrze sprawdzają się denaturat i ręczniki papierowe.</p>
            <h2><strong>Pielęgnacja drewna lakierowanego:</strong></h2>
            <ul>
                <li>Niektóre wyroby drewniane malowane są lakierem meblowym, aby zwiększyć odporność na zabrudzenia. Lakier jest odporny na ścieranie, wodę, większość rozpuszczalników, tłuszcze, lakiery do paznokci, alkohol, wysokie temperatury itd. Zalecamy jednak, by tego nie testować.</li>
                <li>W razie zalania, należy dokładnie usunąć wodę lub inne ciecze z powierzchni mebli, dbając by nie została w szczelinach. Lakier jest odporny na działanie wody natomiast pęknięcia w starym drewnie nie zawsze są lakierem zabezpieczone.</li>
                <li>Pielęgnacja mebli lakierowanych nie jest wymagająca, wystarczy czasem przetrzeć je szmatką i powszechnie dostępnymi środkami do drewna lakierowanego.</li>
            </ul>
            <h2><strong>Drewno Olejowane:&nbsp;</strong></h2>
            <ul>
                <li>Zmywamy delikatnymi &nbsp;środkami do drewna olejowanego, lub wodą z mydłem.</li>
                <li>Nie używamy mikrofibry</li>
                <li>W razie kłopotliwych zanieczyszczeń prosimy o kontakt. Polecimy, lub sprzedamy Państwu specjalistyczny środek firmy ADLER, do czyszczenia powierzchni olejowanych.</li>
            </ul>
            <p>&nbsp;</p>


        </div >


    );
}

export default HowToCare;