import React, { useState, useEffect, useRef, useCallback } from "react";
import toolkit from "../../utils";
import './edit-view.css';
import EditableList from '../admin-list/edit-list';
import ImageGalleryWithUploader from "../edit-images-handler";
import Editor from './editor';
import Quill from 'quill'; 
const Delta = Quill.import('delta');

/**
 * @component EditView
 */ 

const EditView = () => {
    const quillRef = useRef();
    const [images, setImages] = useState([]); // State to store thumbnails
    const [newImages, setNewImages] = useState([]);
    const [productInfo, setProduct] = useState({});
    const [thumbnailImage, setThumbnailImage] = useState("");
  const [lastChange, setLastChange] = useState();
    const [fieldsConfig, setFieldsConfig] = useState();
    const [showVariants, setShowVariants] = useState();
    const [formData, setFormData] = useState({}); // State to store form data
    const [categories, setCategories] = useState();
    const [collections, setCollections] = useState();
    const [options, setOptions] = useState();
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');
    const id = urlParams.get('id');
    const optionId = urlParams.get('optionId');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    // Local state for Quill content
    const [technicalContent, setTechnicalContent] = useState('');
    const [descriptionContent, setDesciptionContent] = useState('');

    const fetchData = async () => {
        // eslint-disable-next-line default-case
        switch (type) {
            case "product":
                let productData = id !== 'new' ? await toolkit.getProductDetails(id) : null;
                let categories = await toolkit.getCategories();
                setCategories(categories);
                let collections = await toolkit.getCollections();
                setCollections(collections);
                let options = await toolkit.getOptions();
                setOptions(options);
                let images = await toolkit.getProductImages(id);
                setImages(images);
                let newProduct = {
                    thumb: "",
                    title: "",
                    description: "",
                    technical: "",
                    category: "",
                    collection: "",
                    options_ids: "",
                    timeline: "",
                    price: 0
                };
                let product = productData ? productData[0] : newProduct;
                setProduct(product)
                setThumbnailImage(product.thumb)
                let productConfigurationToShow = [
                    { id: 2, label: 'Nazwa', fieldName: 'name', type: 'text', placeholder: '', isRequired: true, defaultValue: product.title },
                    { id: 3, label: 'Opis', fieldName: 'description', type: 'textarea', placeholder: '', isRequired: true, defaultValue: product.description },
                    { id: 4, label: 'Opis techniczny', fieldName: 'technical-description', type: 'textarea', placeholder: '', isRequired: true, defaultValue: product.technical },
                    { id: 5, label: 'Kategoria', fieldName: 'category', type: 'text', placeholder: '', isRequired: true, defaultValue: product.category },
                    { id: 6, label: 'Kolekcja', fieldName: 'collection', type: 'text', placeholder: '', isRequired: true, defaultValue: product.collection },
                    { id: 7, label: 'Opcje', fieldName: 'options', type: 'text', placeholder: '', isRequired: true, defaultValue: product.options_ids },
                    { id: 8, label: 'Czas realizacji', fieldName: 'timeline', type: 'text', placeholder: '', isRequired: true, defaultValue: product.timeline },
                    { id: 9, label: 'Cena bazowa', fieldName: 'price', type: 'number', placeholder: '', isRequired: true, defaultValue: product.price },
                ];
                setFieldsConfig(productConfigurationToShow);
                break
            case "collection":
                let collectionData = id !== 'new' ? await toolkit.getCollections() : null;
                // Create an empty template for the fields
                let newCollection = {
                    label: "",
                };
                let collectionConfigurationToShow = [
                    { id: 1, label: 'Nazwa', fieldName: 'name', type: 'text', placeholder: '', isRequired: true, defaultValue: collectionData ? collectionData[id] : newCollection.label }]
                setFieldsConfig(collectionConfigurationToShow);
                break
            case "category":
                let categoryData = id !== 'new' ? await toolkit.getCategories() : null;
                // Create an empty template for the fields
                let newCategory = {
                    label: "",
                };
                let categoryConfigurationToShow = [
                    { id: 1, label: 'Nazwa', fieldName: 'name', type: 'text', placeholder: '', isRequired: true, defaultValue: categoryData ? categoryData[id] : newCategory.label }]
                setFieldsConfig(categoryConfigurationToShow);
                break
            case "delivery":
                let deliveryData = id !== 'new' ? await toolkit.getDelivery() : null;
                let newDelivery = {
                    label: "",
                    price: "",
                };
                let foundElement = deliveryData ? deliveryData.find(element => element.id === parseInt(id)) : newDelivery;
                let deliveryConfigurationToShow = [
                    { id: 1, label: 'Nazwa', fieldName: 'name', type: 'text', placeholder: '', isRequired: true, defaultValue: foundElement.label },
                    { id: 2, label: 'Cena', fieldName: 'price', type: 'text', placeholder: '', isRequired: true, defaultValue: foundElement.price },
                ];
                setFieldsConfig(deliveryConfigurationToShow);
                break
            case "options":
                let optionsData = id !== 'new' ? await toolkit.getOptions() : null;
                let newOptions = {
                    label: "",
                    name: "",
                };
                let foundOption = optionsData ? optionsData.find(element => element.id === parseInt(id)) : newOptions;
                let optionsConfigurationToShow = [
                    { id: 1, label: 'Nazwa opcji', fieldName: 'label-name', type: 'text', placeholder: '', isRequired: true, defaultValue: foundOption.name },
                    { id: 2, label: 'Etykieta wyświetlana w sklepie', fieldName: 'shown-name', type: 'text', placeholder: '', isRequired: true, defaultValue: foundOption.label },
                    // Type Images is not possible to edit for now as we only use it for wood
                ];
                setFieldsConfig(optionsConfigurationToShow);
                setShowVariants(true);
                break
            case "variants":
                let variantsData = id !== 'new' ? await toolkit.getVariants(optionId) : null;
                let newVariants = {
                    label: "",
                    delivery_change: "",
                    value_change: "",
                };
                const foundVariant = variantsData ? variantsData.find(element => element.id === parseInt(id)) : newVariants;
                let variantsConfigurationToShow = [
                    { id: 1, label: 'Wariant', fieldName: 'label', type: 'text', placeholder: '', isRequired: true, defaultValue: foundVariant.label },
                    { id: 2, label: 'Przypisana wysyłka', fieldName: 'delivery_change', type: 'text', placeholder: '', isRequired: true, defaultValue: foundVariant.delivery_change },
                    { id: 3, label: 'Zmiana wartości', fieldName: 'price-change', type: 'text', placeholder: '', isRequired: true, defaultValue: foundVariant.value_change }
                ];
                setFieldsConfig(variantsConfigurationToShow);
                break
            case "wood":
                const woodVariantsData = await toolkit.getVariants("1");
                let woodVariantDataForId;
                let valueChangeForId;
                let woodVariantImagePath;
                if (id !== "new") {
                    woodVariantDataForId = woodVariantsData.find(variant => variant.id === parseInt(id));
                    valueChangeForId = woodVariantDataForId ? woodVariantDataForId.value_change : null;
                    woodVariantImagePath = woodVariantDataForId.path
                    setThumbnailImage(woodVariantImagePath);
                    setImages([{ thumbsPath: woodVariantImagePath, imagePath: woodVariantImagePath }])
                }
                else {
                    valueChangeForId = null;
                    woodVariantDataForId = {};
                    woodVariantDataForId.label = "";
                }
                let woodConfigurationToShow = [
                    { id: 2, label: 'Opis', fieldName: 'label', type: 'text', placeholder: '', isRequired: true, defaultValue: woodVariantDataForId.label },
                    { id: 3, label: 'Zmiana wartości', fieldName: 'price-change', type: 'text', placeholder: '', isRequired: true, defaultValue: valueChangeForId }
                ];
                setFieldsConfig(woodConfigurationToShow);
                break
            case "gallery":
                let galleryData = await toolkit.getPortfolioImages()
                setImages(galleryData)
                break
        }
        const defaultFormData = {};
        if (fieldsConfig) {
            fieldsConfig.forEach((field) => {
                defaultFormData[field.fieldName] = field.defaultValue || '';
            });
        }
        setFormData(defaultFormData);
    };

    const handleChange = useCallback((e, field) => {
        if (!fieldsConfig) {
            console.error("fieldsConfig is undefined");
            return;
        }
    
        if (field.fieldName === "category") {
            const selectedCategoryId = parseInt(e.target.value, 10);
            setFormData((prevData) => {
                const categoryField = fieldsConfig.find((field) => field.fieldName === 'category');
                if (!categoryField) {
                    console.error("categoryField is undefined");
                    return prevData;
                }
                const currentCategoryArray = (prevData.category || categoryField.defaultValue)
                    .replace(/\[|\]/g, '')
                    .split(',')
                    .map(id => parseInt(id, 10))
                    .filter(id => !isNaN(id));
                const updatedCategoryArray = currentCategoryArray.includes(selectedCategoryId)
                    ? currentCategoryArray.filter((id) => id !== selectedCategoryId)
                    : [...currentCategoryArray, selectedCategoryId];
                return {
                    ...prevData,
                    category: updatedCategoryArray.length > 0 ? updatedCategoryArray.join(',') : null,
                };
            });
        } else if (field.fieldName === "options") {
            const selectedOptionsId = parseInt(e.target.value, 10);
            setFormData((prevData) => {
                const optionField = fieldsConfig.find((field) => field.fieldName === 'options');
                if (!optionField) {
                    console.error("optionField is undefined");
                    return prevData;
                }
                const currentOptionsArray = (prevData.options || optionField.defaultValue)
                    .replace(/\[|\]/g, '')
                    .split(',')
                    .map(id => parseInt(id, 10))
                    .filter(id => !isNaN(id));
                const updatedOptionsArray = currentOptionsArray.includes(selectedOptionsId)
                    ? currentOptionsArray.filter((id) => id !== selectedOptionsId)
                    : [...currentOptionsArray, selectedOptionsId];
                return {
                    ...prevData,
                    options: updatedOptionsArray.length > 0 ? updatedOptionsArray.join(',') : null,
                };
            });
        } else if (field.fieldName === "collection") {
            const selectedCollectionId = parseInt(e.target.value, 10);
            setFormData((prevData) => {
                return {
                    ...prevData,
                    collection: selectedCollectionId
                };
            });
        } else if (field.fieldName === "technical-description") {
            setTechnicalContent(e);
        } else if (field.fieldName === "description") {
            setDesciptionContent(e);
        } else {
            // For other fields
            setFormData((prevData) => ({
                ...prevData,
                [field.fieldName]: e.target.value,
            }));
        }
    }, [fieldsConfig, setFormData, setTechnicalContent, setDesciptionContent]);
    

    const handleSubmit = async (e, isDraft = false) => {
        e.preventDefault();
        const defaultFormData = {};
        let newId; // Define newId here

        if (fieldsConfig) {
            fieldsConfig.forEach((field) => {
                defaultFormData[field.fieldName] = field.defaultValue || '';
            });
        }
        if (thumbnailImage) {
            defaultFormData.thumbnailImage = thumbnailImage;
        }

        // Combine defaultFormData with the current formData
        const combinedFormData = { ...defaultFormData, ...formData };

        // Add technical content and description content to combinedFormData
        combinedFormData['technical-description'] = technicalContent;
        combinedFormData['description'] = descriptionContent;
        // Set the draft status based on the parameter
        combinedFormData.draft = isDraft ? 1 : 0;

        // If type is "wood," submit the entire form data
        if (type === "wood") {
            await toolkit.updateItem(type, id, combinedFormData);
            setShowSuccessPopup(true);
        } else {
            // For other types, submit all fields
            if (Object.keys(combinedFormData).length !== 0) {
                newId = await toolkit.updateItem(type, id, combinedFormData);
                setShowSuccessPopup(true);
            }
        }

        if ((type === "product" || type === "gallery") && newImages.length > 0) {
            await toolkit.addImagesForProduct(type === "product" ? (newId || id) : null, newImages);
            setShowSuccessPopup(true);
        }
        setTimeout(() => {
            setShowSuccessPopup(false);
        }, 800);
    };

    const handleSwitchToDraft = async () => {
        const updatedProductInfo = { ...productInfo, draft: 1 };
        const defaultFormData = {};
        let newId;
        if (fieldsConfig) {
            fieldsConfig.forEach((field) => {
                defaultFormData[field.fieldName] = field.defaultValue || '';
            });
        }
        const combinedFormData = { ...defaultFormData, ...formData, ...updatedProductInfo };

        if (thumbnailImage) {
            combinedFormData.thumbnailImage = thumbnailImage;
        }

        if (type === "wood") {
            await toolkit.updateItem(type, id, combinedFormData);
            setShowSuccessPopup(true);
        } else {
            if (Object.keys(combinedFormData).length !== 0) {
                newId = await toolkit.updateItem(type, id, combinedFormData);
                setShowSuccessPopup(true);
            }
        }

        if ((type === "product" || type === "gallery") && newImages.length > 0) {
            await toolkit.addImagesForProduct(type === "product" ? (newId || id) : null, newImages);
            setShowSuccessPopup(true);
        }
    };

    const handlePopupClose = () => {
        setShowSuccessPopup(false);
        // window.location.reload();
        if (type === "product")
            window.location.href = '/listProduct';
        else if (type === "options") {
            window.location.href = '/listOptions';
        }
        else if (type === "wood") {
            window.location.href = '/listWood';
        }
        else if (type === "gallery") {
            window.location.href = '/listGallery';
        }
    };

    const renderCategoryCheckboxes = () => {
        if (fieldsConfig) {
            const categoryField = fieldsConfig.find((field) => field.fieldName === 'category');
            if (categoryField) {
                const categoriesArray = formData.category ? formData.category : categoryField.defaultValue;
                return (
                    <div className="admin-edit-input">
                        <label className="input-admin-label">{categoryField.label}</label>
                        {Object.entries(categories).map(([categoryId, categoryLabel]) => (
                            <div key={categoryId}>
                                <input
                                    type="checkbox"
                                    id={`category-${categoryId}`}
                                    name="category"
                                    value={categoryId}
                                    checked={categoriesArray.includes(categoryId.toString())}
                                    onChange={(e) => handleChange(e, categoryField)}
                                />
                                <label htmlFor={`category-${categoryId}`}>{categoryLabel}</label>
                            </div>
                        ))}
                    </div>
                );
            }
        }

        return null;
    };

    const renderOptionsCheckboxes = () => {
        if (fieldsConfig) {
            const optionsField = fieldsConfig.find((field) => field.fieldName === 'options');
            if (optionsField !== undefined) {
                const optionsArray = formData.options ? JSON.parse("[" + formData.options + "]") : JSON.parse("[" + optionsField.defaultValue + "]");
                console.log("options array", optionsArray, formData)
                return (
                    <div className="admin-edit-input">
                        <label className="input-admin-label">{optionsField.label}</label>
                        {Object.entries(options).map(([optionId, optionData]) => (
                            <div key={optionId}>
                                <input
                                    type="checkbox"
                                    id={`option-${optionId}`}
                                    name="option"
                                    value={parseInt(optionData.id, 10)}
                                    checked={optionsArray.some(id => id === parseInt(optionData.id, 10))}
                                    onChange={(e) => handleChange(e, optionsField)}
                                />
                                <label htmlFor={`option-${optionId}`}>{optionData.name}</label>
                            </div>
                        ))}
                    </div>
                );
            }
        }

        return null;
    };

    const renderCollection = () => {
        if (fieldsConfig) {
            const collectionField = fieldsConfig.find((field) => field.fieldName === 'collection');
            if (collectionField) {
                // Use the value from formData or the default value if formData.collection is not set
                const collectionValue = formData.collection || collectionField.defaultValue;
    
                return (
                    <div className="admin-edit-input">
                        <label className="input-admin-label">{collectionField.label}</label>
                        <select
                            className="list-collection-select"
                            value={collectionValue}
                            onChange={(e) => handleChange(e, collectionField)}
                        >
                            {Object.keys(collections).map((collectionKey) => (
                                <option key={collectionKey} value={parseInt(collectionKey, 10)}>
                                    {collections[collectionKey]}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            }
        }
    
        return null;
    };
    

    const renderImageGallery = () => {
        if (!images) {
            // If images is null or undefined, return the ImageGalleryWithUploader component
            return (
                <ImageGalleryWithUploader
                    images={newImages}
                    setImages={setImages}
                    newImages={newImages}
                    setNewImages={setNewImages}
                    thumbnailImage={thumbnailImage}
                    setThumbnailImage={setThumbnailImage}
                    type={type}
                />
            );
        }

        // If images is defined, proceed with mapping over it
        return (
            <ImageGalleryWithUploader
                images={images.map((image) => ({
                    ...image,
                    selected: image.thumbs_path === thumbnailImage,
                }))}
                setImages={setImages}
                newImages={newImages}
                setNewImages={setNewImages}
                thumbnailImage={thumbnailImage}
                setThumbnailImage={setThumbnailImage}
                type={type}
            />
        );
    };


    useEffect(() => {
        const fetchDataAndRender = async () => {
            await fetchData();
        };

        fetchDataAndRender();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        
        <div className="edit-view">
            {type === "product" && <div className="note">
                <h2>PAMIĘTAJ ŻE WYSYŁKA ZALEŻY OD WYMIARU.</h2>
                <h3>BRAK WYMIARU = WYSYŁKA 0</h3>
            </div>}
            <form id="edit-form" onSubmit={handleSubmit}>
                {fieldsConfig &&
                    fieldsConfig
                        .filter(field => field.fieldName !== 'category' && field.fieldName !== 'collection' && field.fieldName !== 'options')
                        .map((field) => (
                            <React.Fragment key={field.id}>
                                <label className="input-admin-label">{field.label}</label>
                                {field.type !== "textarea" ? (
                                    <input
                                        id={field.id}
                                        type={field.type}
                                        className="form-input form-item"
                                        placeholder={field.placeholder}
                                        defaultValue={field.defaultValue}
                                        required={field.isRequired}
                                        onChange={(e) => handleChange(e, field)}
                                    />
                                ) : (
                                    <Editor
                                    ref={quillRef}
                                    id={field.id}
                                    className="form-input form-textarea"
                                    placeholder={field.placeholder}
                                    defaultValue={field.defaultValue}
                                    onChange={(e) => handleChange(e, field)}
                                    onTextChange={setLastChange}
                                  />
                                )
                                }
                            </React.Fragment>
                        ))}

                {showVariants && <div className="variants-list">
                    {id !== "new" && <EditableList type="variants" />}
                    {id === "new" && <div>
                        <h2 >Aby dodać warianty do opcji:</h2>
                        <li>- Zapisz nową opcje</li>
                        <li>- Wejdź na listę opcji</li>
                        <li>- Kliknij w zapisaną opcję</li>
                    </div>}
                </div>}
                {type === "product" && <div className="product-specific">
                    {renderOptionsCheckboxes()}
                    {renderCategoryCheckboxes()}
                    {renderCollection()}
                    <h2> Zdjęcia produktu</h2>
                    <p> Zapisz zdjęcia a później kliknij w zdjęcie w celu wyboru miniatury</p>
                    {renderImageGallery()}
                </div>}
                {(type === "wood" || type === "gallery") && <div className="product-specific">
                    {renderImageGallery()}
                </div>}
                <div class="button-container">
                    {type === "product" && <button type="submit" className="draft" onClick={handleSwitchToDraft}> ZAPISZ JAKO WERSJĘ ROBOCZA</button>}
                    <button type="submit" className="save-changes" onClick={handleSubmit}>ZAPISZ I OPUBLIKUJ </button>
                </div>
                {showSuccessPopup && (
                    <div className="popup saved">
                        <div className="popup-content">
                            <p>Zapisane!</p>
                            <button className="close-popup" onClick={handlePopupClose}>Zamknij</button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    )
};


export default EditView;
