import React from "react";
import LoginForm from "../../components/login-form/LoginForm";

function Login() {
    const fieldsConfig = {
        successMessage: 'Pomyślnie zalogowano',
        errorMessage: 'Wystąpił błąd. Spróbuj ponownie lub zadzwoń do Żony',
        fieldsConfig: [
            { id: 1, label: 'Login', fieldName: 'login', type: 'text', placeholder: '', isRequired: true, className: 'gmo-input w3-padding-16' },
            { id: 2, label: 'Hasło', fieldName: 'password', type: 'password', placeholder: '', isRequired: true, className: 'gmo-input w3-padding-16' },
        ]
    
    }

    return (
        <div className="login">
            <div className="gmo-padding-large" id="main">
                <div className="gmo-padding gmo-content gmo-text-grey" id="login">
                    <h2 className="gmo-text-light-grey">Zaloguj się</h2>
                    <hr styles={{ width: `200px` }} className="gmo-opacity" />
                    <LoginForm config={fieldsConfig} />
                
                </div>
            </div>
        </div >
    );
}

export default Login;