import React from "react";
import "./about.css";
import HowToBuy from '../../components/how-to-buy/how-to-buy';
import BestFurnitures from '../../components/best-furnitures/best-furnitures';
import Collaboration from '../../components/collaboration/collaboration';

function About() {

    return (
        <div className="about">

            {<div className="gmo-padding-large" id="main">
                <section id="home" className="home-section home-parallax home-fade home-full-height">
                    <header className="gmo-container gmo-padding-32 gmo-center gmo-black" id="home">
                        <div className="flex-active-slide"></div>
                        <div className="home-slider-overlay"></div>
                        <div className="caption">
                            <div className="caption-content">
                                <h1 className="hs-title-size-4 font-alt mb-30">Najwyższej jakości meble z litego drewna</h1>
                                <div className="hs-title-size-1 font-alt mb-40">Bo liczy się każdy detal</div>
                                <a href="/shop" className="section-scroll btn btn-border-w btn-round">Zobacz kolekcje </a>

                            </div>
                        </div>
                    </header>
                </section>
                <HowToBuy />

                <div className="gmo-content gmo-justify gmo-text-grey gmo-padding" id="about">
                    <div className="about-brand">
                        <h2 className="gmo-text-light-grey ">O nas</h2>
                        <hr styles={{ width: `200px` }} className="gmo-opacity"></hr>

                        <p> STARE I ZNISZCZONE DESKI SĄ JAK SZLACHETNE KAMIENIE.  </p>
                        <p>  Na pierwszy rzut oka szare, omszałe i nieciekawe. </p>
                        <p>  Drewno drugiego gatunku posiada swój charakter, który definiują jego niedoskonałości. </p>
                        <p>  Te wady nadają mu unikalny urok, czyniąc je jednym w swoim rodzaju.</p>
                        <p>  W GMO Design meble wiemy jak poddać każde drewno odpowiedniej obróbce, by wydobyć to, co wartościowe. </p>

                    </div>
                    <BestFurnitures />
                    <Collaboration />

                </div>
            </div>}
        </div>
    );

}

export default About;