import React from "react";
import "./collaboration.css";


function Collaboration() {
    return (
        <div className="collaboration">
           <h2 className="gmo-text-light-grey">Współpracujemy z </h2>
                    <hr style={{ width: `200px` }} className="gmo-opacity"></hr>
                    <div className="gmo-row-padding" styles={{ margin: `0 -16px` }}>
                            <img src="/media/1.jpg" alt=""></img>
                            <img src="/media/2.jpeg" alt=""></img>
                            <img src="/media/3.jpg" alt=""></img>
                            <img src="/media/4.jpg" alt=""></img>
                            <img src="/media/5.png" alt=""></img>
                            <img src="/media/6.png" alt=""></img>
                    </div>
        </div>
    )
}


export default Collaboration;