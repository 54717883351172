import React from "react";

const GDPRCheckbox = ({ id, checked, onChange, red }) => {
    const checkboxStyle = red ? { color: "red" } : {};
    return (
        <div className="gdpr-checkbox" id="gdprConsent">
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onChange}
            />
              <label htmlFor={id} style={checkboxStyle}>
                "Oświadczam, iż dobrowolnie wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w załączonym formularzu kontaktowym. Jednocześnie przyjmuję do wiadomości, że moje dane będą przetwarzane zgodnie z aktualnie obowiązującymi przepisami prawa dotyczącymi przetwarzania danych osobowych oraz że: 1. Administratorem danych osobowych jest: GMO DESIGN Maciej Czyżewski. 2. Moje dane osobowe przetwarzane będą wyłącznie w celu realizacji zamówienia na podstawie udzielonej przeze mnie dobrowolnej zgody. 3. Moje dane osobowe będą przetwarzane przez okres prowadzenia działalności GMO Design Maciej Czyżewski lub do czasu wycofania przeze mnie zgody. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem. Wyrażenie zgody przez mnie jest dobrowolne. 4. Posiadam prawo dostępu do treści moich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo cofnięcia zgody w dowolnym momencie. 5. Mam prawo wniesienia skargi do organu nadzorczego, gdy uznam, iż przetwarzanie moich danych osobowych narusza przepisy prawa dotyczące ochrony danych osobowych. Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z polityką prywatności." *
            </label>
        </div>
    );
};

export default GDPRCheckbox;
