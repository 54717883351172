import React, { useState } from "react";
import axios from "axios";
import toolkit from "../utils";

const ImageUploader = ({ onUpload , type }) => {
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    let folderToUpload; 
    switch (type) {
      case "product": 
        folderToUpload = "products";
        break;
      case "wood":
        folderToUpload = "probki";
        break;
      case "gallery":
        folderToUpload = "gallery";
        break;
    }
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("destinationPath", folderToUpload);

      try {
        const response = await axios.post(toolkit.setReactAppApi("upload-image"), formData);
        onUpload({ imagePath: response.data.imagePath, thumbsPath: response.data.thumbsPath });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <div className = "image-uploader">
      <input type="file" onChange={handleImageChange} />
    </div>
  );
};

export default ImageUploader;
