import React from "react";
import "./best-furnitures.css";


function BestFurnitures() {
    return (
        <div className="bestFurnitures">
            <div className="image-gallery">
                <img src="media/furnitures/1.jpg" alt="Drewniane meble w kuchni" />
                <img src="media/furnitures/2.jpg" alt="Stół z bali na szklanych nogach" />
                <img src="media/furnitures/3.jpg" alt="Stół loft" />
            </div>
        </div>
    )
}


export default BestFurnitures;