import React from 'react';
import About from '../../pages/about/About';
import Contact from '../../pages/contact/Contact';
import "./Home.css";

function Home() {

  return (
    <div className="home">
      <About />
      <Contact />
    </div>

  );
}

export default Home;