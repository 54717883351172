import React, { useEffect, useState } from 'react';
import './gallery.css';
import toolkit from "../../utils";
import Lightbox from "../lightbox/Lightbox";

function Gallery() {
    const [images, setImages] = useState([]);
    const [lightboxImageIndex, setLightboxImageIndex] = useState(null);
    useEffect(() => {
        const fetchAndSetData = async () => {
            try {
                let data = await toolkit.getPortfolioImages ();
                setImages(data);
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };
        fetchAndSetData();
    }, []);


    const openLightbox = (index) => {
        setLightboxImageIndex(index);
    };

    const closeLightbox = () => {
        setLightboxImageIndex(null);
    };

    const prevImage = () => {
        if (lightboxImageIndex !== null) {
            setLightboxImageIndex(lightboxImageIndex === 0 ? images.length - 1 : lightboxImageIndex - 1);
        }
    };

    const nextImage = () => {
        if (lightboxImageIndex !== null) {
            setLightboxImageIndex(lightboxImageIndex === images.length - 1 ? 0 : lightboxImageIndex + 1);
        }
    };

    return (
        <div className="gmo-padding-large" id="gallery">
            <div className="image-grid">
                {images.map((image, index) => (
                    <img
                        key={image.id}
                        src={image.thumbs_path}
                        alt={image.altText}
                        className={`grid-item ${index % 3 === 0 ? 'large' : 'small'}`}
                        onClick={() => openLightbox(index)}
                    />
                ))}
            </div>
            {lightboxImageIndex !== null && (
                <Lightbox
                    images={images}
                    currentImageIndex={lightboxImageIndex}
                    onClose={closeLightbox}
                    onPrev={prevImage}
                    onNext={nextImage}
                />
            )}
        </div>

    );
}

export default Gallery;