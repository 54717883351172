import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./contact-form.css";
import toolkit from "../../utils";
import GDPRCheckbox from "../../components/gdprCheckbox";
const ContactForm = (props) => {
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ "from": "no-reply@gmo-design.pl", "subject": "Wiadomość z formularza kontaktowego" });
  const [redCheckbox, setCheckboxToRed] = useState(false);
  const [honeypotField, setHoneypotField] = useState("");
  const [answer, setAnswer] = useState(""); // State to store the user's answer
  const [isHuman, setIsHuman] = useState(true); // State to track if user is human
  const [emailError, setEmailError] = useState(""); // State to track email validation error
  const [isSendingVisible, setSendingVisibility] = useState(false);
  const question = "Ile to 2 + 2 (potwierdź, że nie jesteś botem) ";
  const correctAnswer = "4";

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let dontSubmit = false;
    if (redCheckbox || !formData.gdprConsent) {
      setCheckboxToRed(true);
      dontSubmit = true;
    }
    if (honeypotField) {
      dontSubmit = true;
      setEmailError("Pojawił się problem na stronie. Proszę skontaktuj się z nami telefonicznie");
      return;
    }
    if (answer !== correctAnswer) {
      setIsHuman(false);
      dontSubmit = true;
    }
    if (!validateEmail(formData.from)) {
      setEmailError("Proszę podaj poprawny mail.");
      dontSubmit = true;
    }
    if (!dontSubmit) {
      setSendingVisibility(true);
      axios({
        method: "post",
        url: `${toolkit.setReactAppApi("api")}`,
        headers: { "content-type": "application/json" },
        data: formData
      })
        .then(result => {
          if (result.status === 200) {
            setMailSent(true);
            setError(false);
            setSendingVisibility(false);
            setTimeout(() => {
              setMailSent(false);
            }, 1000);
          } else {
            setError(true);
          }
        })
    }

  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleChange = (e, field) => {
    const { value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    const fieldName = type === 'checkbox' ? field.fieldName : field;
    if (type === "checkbox") {
      setCheckboxToRed(!fieldValue);
    }
    if (fieldName === "honeypot") {
      setHoneypotField(value);
    }
    if (fieldName === "answer") {
      setAnswer(value);
      if (value !== correctAnswer) {
        setIsHuman(false);
      }
      else {
        setIsHuman(true);
      }
    }
    if (type === "email") {
      validateEmail(fieldValue) ? setEmailError() : setEmailError("Proszę podaj poprawny mail.");
    }

    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: fieldValue,
    }));

  };

  const { title, description, successMessage, errorMessage, fieldsConfig } = props.config;
  return (
    <div className="contact-form">
      {mailSent && <div className="success"><p className="succesMessage">{successMessage}</p></div>}
      {error && <div className="error">{errorMessage}</div>}
      {isSendingVisible && (
        <div className="cart-sending">
          <div className="spinner-container spinner-sending-container">
            <div className="spinner"></div>
          </div>
        </div>
      )}

      <div className="contact-form__header">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="contact-form__container">
        <div>
          <form action="#">
            {fieldsConfig &&
              fieldsConfig.map(field => {
                return (
                  <React.Fragment key={field.id}>
                    {field.type !== "textarea" ? (
                      <React.Fragment>
                        <label>{field.label}</label>
                        <input
                          type={field.type}
                          className={field.className}
                          placeholder={field.placeholder}
                          value={field.name}
                          onChange={(e) => handleChange(e, field.fieldName)}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <label>{field.label}</label>
                        <textarea className={field.className} placeholder={field.placeholder} onChange={(e) => handleChange(e, field.fieldName)} value={field.name} />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
            {emailError && <div className="error">{emailError}</div>} {/* Display email validation error */}
            <div className={isHuman ? "question" : "question error"}>
              <label>{question}</label>
              <input className={isHuman ? "gmo-input" : "gmo-input error"} type="text" name="answer" value={answer} onChange={(e) => handleChange(e, "answer")} />
              {!isHuman && <div className="error">Proszę odopowiedz poprawnie na pytanie w celu potwierdzenia, że nie jesteś botem.</div>}
            </div>

            <GDPRCheckbox
              id="gdprConsent"
              checked={formData.gdprConsent}
              onChange={(e) =>
                handleChange(e, {
                  fieldName: "gdprConsent",
                  type: "checkbox",
                })
              }
              red={redCheckbox}
            />
            <input type="text" name="honeypot" style={{ display: "none" }} onChange={(e) => handleChange(e, "honeypot")} />
            <input className="submit-button contact-submit" type="submit" onClick={(e) => { handleFormSubmit(e); }} value="Wyślij" />

            <div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;

ContactForm.propTypes = {
  config: PropTypes.object.isRequired
};
