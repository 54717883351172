import React from "react";
import ContactForm from "../../components/contact-form/ContactForm";
import "./Contact.css"

/**
 * @config preparing config prop.
 * api: url for the server endpoint
 * title: form title
 * successMessage: message will show in the UI when mail is successfully sent.
 * errorMessage: message will show in the UI when mail is not sent.
 * fields: this is the name of each field. This should be exact order of the fieldsConfig and fieldsConfig.fieldName should be  the same
 * fieldsConfig = settings for each input/textarea field
 */
const config = {
    title: '',
    description: 'Napisz do nas!',
    successMessage: 'Dziękujemy za wiadomość. Odezwiemy się najszybciej jak to możliwe',
    errorMessage: 'Wystąpił błąd. Spróbuj ponownie lub wyślij maila na adres gmo@gmo-design.pl',
    fieldsConfig: [
        { id: 1, label: 'Imię', fieldName: 'Name', type: 'text', placeholder: '', isRequired: true, className: 'gmo-input w3-padding-16' },
        { id: 2, label: 'Nazwisko', fieldName: 'lastName', type: 'text', placeholder: '', isRequired: true, className: 'gmo-input w3-padding-16' },
        { id: 3, label: 'Treść wiadomości', fieldName: 'msg', type: 'textarea', placeholder: '.....', isRequired: true, className: 'gmo-input w3-padding-16' },
        { id: 4, label: 'E-mail', fieldName: 'replyTo', type: 'email', placeholder: '', isRequired: true, className: 'gmo-input w3-padding-16' }
    ]
}

function Contact() {

    return (
        <div className="contact">
            <div className="gmo-padding-large" id="main">
                <div className="gmo-padding gmo-content gmo-text-grey">
                    <h2 className="gmo-text-light-grey">Skontaktuj się z nami</h2>
                    <hr styles={{ width: `200px` }} className="gmo-opacity" />
                    <div className="contact-flexi">
                        <div className="gmo-section contact-adress">
                            <h3 id="contact">Biuro - pracownia</h3>
                            <p><i className="fa fa-exclamation fa-fw gmo-text-white gmo-xxlarge gmo-margin-right"></i>Proszę dzwonić przed wizytą</p>
                            <p><i className="fa fa-map-marker fa-fw gmo-text-white gmo-xxlarge gmo-margin-right"></i> Ustronie Górne 52, Nowy Targ</p>

                            <p><i className="fa fa-phone fa-fw gmo-text-white gmo-xxlarge gmo-margin-right"></i> Telefon: +48 513 556 116</p>
                            <p><i className="fa fa-envelope fa-fw gmo-text-white gmo-xxlarge gmo-margin-right"> </i>E-mail <a href="mailto: biuro@gmo-design.pl">biuro@gmo-design.pl</a></p>
                        </div>

                        <ContactForm config={config} />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Contact;