import React from "react";
import "./Footer.css";

/**
 * @component Footer
 * @props - { object } -  config
 */
const Footer = () => {

  return (

    <div className="footer">

      <div className="container">
        <div className="footer-row row">

          <div className="col-sm-6 col-md-3 footer-sidebar-wrap">
            <div id="nav_menu-3" className="widget widget_nav_menu">
              <h3 className="widget-title">Sklep</h3>
              <div className="menu-menu-stopka-container">
                <ul id="menu-menu-stopka" className="footer-list  menu">
                  <li id="menu-item-9527" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9527"><a href="/shop/">Sklep</a></li>
                  <li id="menu-item-9530" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9530"><a href="/zamowienie/">Zamówienie i dostawa</a></li>
                  <li id="menu-item-9538" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9538"><a href="/regulamin/">Regulamin</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 footer-sidebar-wrap">
            <div id="nav_menu-5" className ="widget widget_nav_menu">
              <h3 className="widget-title">Kolekcje</h3>
              <div className="menu-kolekcje-menu-stopka-container">
                <ul id="enu-kolekcje-menu-stopka" className="footer-list menu">
                  <li id="menu-item-11627" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11627"><a href="/shop/?collection=3">NANO</a></li>
                  <li id="menu-item-12471" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-12471"><a href="/shop/?collection=2">NaX</a></li>
                  <li id="menu-item-12472" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-12472"><a href="/shop/?collection=4">SAN-Ki</a></li>
                  <li id="menu-item-12557" className="menu-item menu-item-type-taxonomy menu-item-object-product_tag menu-item-12557"><a href="/shop/?collection=5">Kostka</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 footer-sidebar-wrap">
            <div id="text-3" className="widget widget_text">
              <h3 className="widget-title">Dane Firmy</h3>
              <div className="textwidget">
                <p>Ustronie Górne 52
                  34-400 Nowy Targ</p>
                <p>NIP: 944 206 80 11</p>
                <p>Numer konta:
                  92160014621893922780000001</p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 footer-sidebar-wrap">
            <div id="nav_menu-4" className="widget widget_nav_menu">
              <h3 className="widget-title">O nas</h3>
              <div className="menu-menu-stopka-o-nas-container">
                <ul id="menu-menu-stopka-o-nas" className="footer-list menu">
                  <li id="menu-item-9539" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9539"><a href="/contact">Formularz kontaktowy </a></li>
                  <li id="menu-item-9540" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9540"><a href="/pielegnacja-drewna/">Pielęgnacja drewna</a></li>
                  <li id="menu-item-10957" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-10957"><a rel="privacy-policy" href="/polityka-prywatnosci/">Polityka Prywatności</a></li>
                  <li id="menu-item-13040" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13040"><a href="/probki">Próbki Drewna</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>




    </div>
  );

}

export default Footer;
