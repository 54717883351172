import React, { useEffect, useState, useCallback } from 'react';
import "./Product-Details.css";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import toolkit from "../../utils";


function ProductDetails() {
  const urlParams = new URLSearchParams(window.location.search);
  const productId = urlParams.get('id');
  const [images, setImages] = useState([]);
  const [product, setData] = useState([]);
  const [category, setCategory] = useState();
  const [productOptions, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [valueChange, setValueChange] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [gallerySize, setGallerySize] = useState({ size: "700px" });
  const [cartHash, setCartHash] = useState(localStorage.getItem("CartHash"));
  const [deliveryData, setDelivery] = useState([]);
  const [deliveryPrice, setDeliveryPrice] = useState();
  const [isPopupVisible, setPopupVisibility] = useState(false);

  function calculateTotalValueChange(options, selectedValues) {
    let totalValueChange = 0;
    let deliveryPrice = 0;
    // Iterate through selected values
    for (const [label, selectedVariantLabel] of Object.entries(selectedValues)) {
      // Find the option with the corresponding label
      const option = options.find((opt) => opt.label === label);
      if (option) {
        // Find the selected variant
        const selectedVariant = option.variants.find(
          (variant) => variant.label === selectedVariantLabel
        );
        if (selectedVariant) {
          // Add the valueChange to the total
          totalValueChange += parseInt(selectedVariant.value_change, 10);
          if (selectedVariant && deliveryData) {
            if (selectedVariant.delivery_change > 0) {
              let deliveryValue = deliveryData.find(element => element.id === parseInt(selectedVariant.delivery_change));
              deliveryPrice = deliveryValue.price;
            }
          }
        }
      }
    }
    return [totalValueChange, deliveryPrice];
  }

  const handleImageChange = (optionName, selectedOption) => {
    let selectedVariant = document.getElementsByClassName("selected");
    let variantToBeSelected = document.getElementById(selectedOption);
    selectedVariant[0].classList.toggle("selected");
    variantToBeSelected.classList.toggle("selected");
    handleSelectChange(optionName, selectedOption)
  };

  // Memoize handleSelectChange using useCallback
  const handleSelectChange = useCallback((optionName, selectedOption) => {
    setSelectedOptions(prevValues => ({
      ...prevValues,
      [optionName]: selectedOption,
    }));
    let [valueChange, deliveryChange] = calculateTotalValueChange(productOptions, {
      ...selectedOptions,
      [optionName]: selectedOption,
    });

    setValueChange(valueChange);
    setDeliveryPrice(deliveryChange);
  }, [productOptions, selectedOptions]);

  // Load category
  useEffect(() => {
    const getDataFromDB = async () => {
      let categories = await toolkit.getCategories();
      setCategory(categories[product.category]);
    };
    if (!category) {
      getDataFromDB();
    }
  }, [category, product.category]); // Include category in the dependency array

  // Load delivery
  useEffect(() => {
    const getDataFromDB = async () => {
      let delivery = await toolkit.getDelivery();
      setDelivery(delivery);
    };

    if (!deliveryData.length > 0) {
      getDataFromDB();
    }
  }, [deliveryData, selectedOptions, productOptions]); // Include selectedOptions and productOptions in the dependency array

  // Value change
  useEffect(() => {
    // Function to calculate total value change
    const [totalValueChange, deliveryChange] = calculateTotalValueChange(productOptions, selectedOptions);

    setValueChange(totalValueChange);
    setDeliveryPrice(deliveryChange);

    const countQuantity = async () => {
      await toolkit.countAndUpdateCartQuantity(cartHash);
    };
    countQuantity();
  }, [productOptions, selectedOptions, cartHash]);

  // Images
  useEffect(() => {
    const fetchAndSetImagesData = async (productId) => {
      try {
        const data = await toolkit.getProductImages(productId);
        let imagesData = data.map((image) => ({
          src: image.image_path,
          thumbnail: image.thumbs_path
        }))
        setImages(imagesData);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    // Fetch images
    fetchAndSetImagesData(productId);
  }, [productId]);

  // Product 
  useEffect(() => {
    // Product options
    const getAndSetProductOptions = async (optionsIds) => {
      try {
        let optionsData = [];
        // Use Promise.all to wait for all asynchronous calls
        await Promise.all(optionsIds.map(async (optionId) => {
          let optionsDataFromDB = await toolkit.getOptions(optionId);
          if (optionsDataFromDB[0]) {
            let variantsForOption = await toolkit.getVariants(optionId);
            optionsDataFromDB[0].variants = variantsForOption;
            optionsData.push(optionsDataFromDB[0]);
          }
        }));
        // Set initial setup of options if the user won't change anything
        optionsData.forEach((option) => {
          handleSelectChange(option.label, option.variants[0].label);
        });

        setOptions(optionsData);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    // Product details
    const getAndSetProductDetails = async (productId) => {
      try {
        const data = await toolkit.getProductDetails(productId);
        const productOptionsIds = data[0].options_ids.replace(/\[|\]/g, '').split(',')
        setData(data[0]);

        await getAndSetProductOptions(productOptionsIds);
      } catch (error) {
        console.error('An error occurred here:', error);
      }
    };

    getAndSetProductDetails(productId);
  }, [productId]);

  // Handle initial select
  useEffect(() => {

  }, [product]);

  // Handle size of screen
  useEffect(() => {
    if (window.innerWidth < 700) {
      setGallerySize({ size: "300px" });
    }
  }, []);

  useEffect(() => {
    if (!cartHash) {
      let randomUID = crypto.randomUUID();
      setCartHash(randomUID);
      localStorage.setItem("CartHash", randomUID);
      localStorage.setItem("CartQuantity", 0)
    }
  }, [])

  const handleClick = event => {
    // remove tab that is active:
    let activeTab = document.getElementsByClassName("active");
    if (activeTab) { activeTab[0].classList.remove("active"); }
    // hide all text
    let texts = document.getElementsByClassName("product-Tabs-panel")
    for (var i = 0; i < texts.length; i++) {
      texts[i].style.display = "none";
    }
    let id = event.currentTarget.id
    let elementToBeShown = document.getElementById(`${id}-text`)
    elementToBeShown.style.display = "";
    event.currentTarget.classList.toggle('active');

  };

  // Quantity change
  const handleQuantityChange = (event) => {
    // Ensure the value is a non-negative integer
    const newQuantity = Math.max(1, Math.floor(event.target.value));
    setQuantity(newQuantity);
  };

  // Add to cart
  const addItemsToCart = async () => {
    let objectToSave = {
      title: product.title,
      options: selectedOptions,
      quantity: quantity,
      productId: productId,
      deliveryPrice
    }
    // Save cart to DB 
    await toolkit.addToCart(objectToSave, cartHash, quantity);

    // Update Cart Quantity
    await toolkit.countAndUpdateCartQuantity(cartHash);

    setPopupVisibility(true);
    toolkit.updateCartQuantity();
    setTimeout(() => {
      setPopupVisibility(false);
    }, 1000);
  };

  const closePopup = () => {
    setPopupVisibility(false);
  }

  return (
    <div className="product-details"  >
      <div className="product-breadcrumbs">
        <a href="shop" className="href-product-breadcrumbs" >Sklep/ </a>
        <a href={"shop/?category=" + product.category} className="href-product-breadcrumbs"> {category} </a>
      </div>

      <h1 className="product-heading"> {product.title}</h1>
      <div className="product-container">
        <div className="product-images">
          <div className="images-carousel" >
            <Carousel
              images={images}
              hasIndexBoard="false"
              thumbnailWidth="200px"
              thumbnailHeight="100px"
              hasMediaButton="false"
              style={{ width: gallerySize, backgroundColor: "white", maxHeight: "650px" }}
            />
          </div>
        </div>
        <div className="product-options">
          {productOptions.map((option) => (
            <div className="product-select-label" >
              {option.type === "image" && <div className="images-to-select">
              {option.label}: {selectedOptions[option.label]}
                <div id="images_options" >
                  {option.variants.map((variant) => (
                    <div className={option.variants[0].label === variant.label ? "variant-image-div selected" : "variant-image-div"} id={variant.label} onClick={(e) => handleImageChange(option.label, variant.label)}>
                      <p>{variant.label}</p>
                      <img className="variant-image" src={variant.path} value={variant.label} alt={variant.label}></img>
                    </div>
                  ))}
                </div>
                <p style={{ fontSize: 'small' }}> Zobacz powiększoną wersję próbek <a href="/probki" target="_blank"> TUTAJ</a> </p>
              </div>
              }
              {option.type === "select" && <select id="product-options-select" className="product-select" onLoad={(e) => handleSelectChange(option.label, e.target.value)} onChange={(e) => handleSelectChange(option.label, e.target.value)}>
                {option.label}
                {option.variants.map((variant) => (
                  <option value={variant.label} >{variant.label} + {parseInt(variant.value_change)} PLN</option>
                ))}
              </select>
              }
            </div>
          ))}
          <div className="product-count"> Ilość

            <select
              id="quantity"
              name="quantity"
              className="product-quantity"
              value={quantity}
              onChange={handleQuantityChange}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="final-price">
            <div>
              <span>Cena bazowa</span>
              <span className="price amount">{product.price * quantity} PLN</span>
            </div>
            <div>
              <span>Opcje dodatkowe</span>
              <span className="price amount">{parseInt(valueChange) * quantity} PLN</span>
            </div>
            <div className='final'>
              <span>Cena ostateczna</span>
              <span className="price amount">{(parseInt(product.price) + parseInt(valueChange)) * parseInt(quantity)} PLN</span>
            </div>
          </div>
          <div className="product-timeline">
            Przewidywany czas realizacji: {product.timeline}
          </div>
          {deliveryData.length > 0 && (
            <div className="product-delivery-info">
              <p> Szacowana kwota dostawy: {deliveryPrice} PLN </p>
            </div>
          )}
          {isPopupVisible && (
            <div className="popup-container">
              <div className="popup">
                <button className="close-popup" onClick={() => closePopup()}>X</button>
                <div className="popup-text"> Produkt został dodany do koszyka. </div>
              </div>
            </div>
          )}
          <button className="add-to-cart" onClick={() => addItemsToCart()}>
            Dodaj do koszyka
          </button>
        </div>
      </div>
      <div className='product-description-container'>
        <div className="product-tabs">
          <ul className="tabs" role="tablist">
            <li className='description-tab active' onClick={handleClick} id="description" role="tab" aria-controls="tab-custom_tab">
              <a href="#tab-custom_tab">Informacje</a>
            </li>
            <li className='product-tab technical_tab' onClick={handleClick} id="technical" role="tab" aria-controls="tab-description">
              <a href="#tab-description"> Dane Techniczne	</a>
            </li>
            <li className='product-tab delivery_tab' onClick={handleClick} id="delivery" role="tab" aria-controls="tab-delivery">
              <a href="#tab-delivery"> Dostawa	</a>
            </li>
          </ul>
          {/* Tabs starts here  */}
          <div className="product-Tabs-panel description-content" id="description-text" role="tabpanel" aria-labelledby="tab-title-custom_tab" style={{}}>
            {product.description && <div dangerouslySetInnerHTML={{ __html: product.description }} />}
          </div>

          <div className="product-Tabs-panel technical-content" id="technical-text" role="tabpanel" aria-labelledby="tab-title-description" style={{ display: `none` }}>
            {product.technical && <div dangerouslySetInnerHTML={{ __html: product.technical }} />}
          </div>

          <div className="product-Tabs-panel delivery" id="delivery-text" role="tabpanel" aria-labelledby="tab-title-description" style={{ display: `none` }}>
            <p> Dokładamy wszelkich starań, abyś mógł się jak najszybciej cieszyć produktami zamówionymi w naszym sklepie. </p>
            <p> Czas realizacji zamówienia prezentowany w opisie produktu to czas, który potrzebujemy aby stworzyć Twój mebel. </p>
            <p> Do czasu realizacji zamówienia należy doliczyć czas potrzebny na dostawę.</p>
            <p> Informacje o czasie realizacji oraz planowanej dacie wysyłki lub dostawy otrzymasz w mailu potwierdzającym przyjęcie zamówienia. </p>
            <p> Czarter z wniesieniem: cena do uzgodnienia w zależności od miejscowości</p>
            <p> Przesyłka kurierska:</p>
            <ul>
              {deliveryData.map((option) => (
                <li>- {option.label} : {option.price === 0 ? "Do uzgodnienia " : option.price + " PLN"}</li>
              ))}
            </ul>
            Jeżeli są Państwo zainteresowani dostawą poza granice naszego kraju (na terenie Europy), prosimy o kontakt z mailowy w celu uzgodnienia szczegółów dostawy.
          </div>

        </div>
      </div>

      <div className='product-footer-container'>
        <div className='img-product-footer-container'>
          <img src="/product-footer.jpg" alt="Autorskie meble GMO meble" ></img></div>
        <div className='product-footer-description'><h1>Standard GMO Meble</h1>
          <p> GMO meble to młoda marka oferująca unikatowe meble</p>
          <p>Każdy mebel w naszej ofercie jest autorskim projektem Macieja Czyżewskiego </p>
          <p> Produkty od początku do końca wykonujemy w Polse. </p>
          <p> Stawiamy na wysoką jakość</p>
          <p>Zamawiając u nas masz możliwość wyboru nie tylko rodzaju drewna ale również tego czy mebel ma być z sękami i charakterem czy gładki. </p>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;