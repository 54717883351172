import React from 'react';
import './Lightbox.css';

function Lightbox({ images, currentImageIndex, onClose, onPrev, onNext }) {
  const image = images[currentImageIndex];
  return (
    <div className="lightbox">
      <div className="lightbox-content">
        <button onClick={onPrev} className="lightbox-button prev">
          &lt;
        </button>
        <img src={image.image_path} alt={image.altText} />
        <button onClick={onNext} className="lightbox-button next">
          &gt;
        </button>
        <button onClick={onClose} className="lightbox-button close-button">
          X
        </button>
      </div>
    </div>
  );
}

export default Lightbox;