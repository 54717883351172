import React, { useState, useEffect } from "react";
import "./shop.css";
import toolkit from "../../utils";

function Shop() {
    const [isLoading, setViewStatus] = useState(true);
    const [categories, setCategories] = useState();
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [collections, setCollections] = useState();
    const [selectedCollection, setSelectedCollection] = useState(1);
    const [products, setProducts] = useState([]);
    const [initialRender, setInitialRender] = useState(true);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const collectionId = parseInt(new URL(window.location.href).searchParams.get('collection'));
        if (collectionId > 0) {
            setSelectedCollection(parseInt(collectionId));
        }
    }, []);

    useEffect(() => {
        const categoryId = parseInt(new URL(window.location.href).searchParams.get('category'));
        if (categoryId > 0) {
            setSelectedCategory(parseInt(categoryId));
        }
    }, []);

    // Fetch categories and collections
    useEffect(() => {
        const fetchData = async () => {
            try {
                const categoriesData = await toolkit.getCategories();
                const collectionsData = await toolkit.getCollections();

                setCategories(categoriesData);
                setCollections(collectionsData);
                setViewStatus(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    // Load products
    useEffect(() => {
        const loadProducts = async () => {
            let products = await toolkit.getShopProducts();
            // Filter out products with draft equal to 1
            if (products)
                products = products.filter(product => product.draft !== 1);
            setProducts(products);
        }
        loadProducts();
    }, [])

    // Filter products based on selected category and collection
    useEffect(() => {
        if (!initialRender) {
            const filterProducts = () => {
                let filtered = products;

                if (selectedCategory !== 1) {
                    filtered = filtered.filter(product => product.category.includes(selectedCategory));
                }
                if (selectedCollection !== 1) {
                    filtered = filtered.filter(product =>
                        product.collection.includes(selectedCollection)
                    );
                }

                setFilteredProducts(filtered);
            };

            filterProducts();
        } else {
            setInitialRender(false);
        }
    }, [selectedCategory, selectedCollection, products, initialRender]);


    // Bold selected collection
    useEffect(() => {
        let elementToBold = document.getElementById("collection-" + selectedCollection);
        if (elementToBold)
            elementToBold.classList.add("bold");
    }, [selectedCollection, collections, products]);

    // Bold selected category
    useEffect(() => {
        let elementToBold = document.getElementById("category-" + selectedCategory);
        if (elementToBold)
            elementToBold.classList.add("bold");
    }, [selectedCategory, categories, products]);

    const handleCategoryClick = (categoryId) => {
        let elementUnclicked = document.getElementById("category-" + selectedCategory);
        if (elementUnclicked != null) {
            elementUnclicked.classList.remove("bold");
        }
        setSelectedCategory(parseInt(categoryId));
        let elementToBold = document.getElementById("category-" + categoryId);
        elementToBold.classList.add("bold");
    };

    const handleCollectionClick = (collectionId) => {
        let elementUnclicked = document.getElementById("collection-" + selectedCollection);
        if (elementUnclicked != null) {
            elementUnclicked.classList.remove("bold");
        }
        setSelectedCollection(parseInt(collectionId));
        let elementToBold = document.getElementById("collection-" + collectionId);
        elementToBold.classList.add("bold");
    };

console.log("Produkty", filteredProducts.length)
    return (
        <div className="shop">
            {isLoading && <div className="loading">
                <div className="divider" />
                <p>Wczytujemy listę produktów.</p>
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            </div>}

            {!isLoading && <div className="shop">
                <div className="filter-container">
                    <div id="filter-category" className="filter-by-category">
                        <h2 className="filter-name" >Kategorie</h2>
                        {categories && Object.entries(categories).map(([categoryId, categoryName]) => (
                            <div
                                id={"category-" + categoryId}
                                key={categoryId}
                                onClick={() => handleCategoryClick(categoryId)}
                                className="clickable-category"
                            >
                                {categoryName}
                            </div>
                        ))}
                    </div>
                    <div id="filter-collection" className="filter-by-collection">
                        <h2 className="filter-name">Kolekcje</h2>
                        {collections && Object.entries(collections).map(([collectionId, collectionName]) => (
                            <div
                                id={"collection-" + collectionId}
                                key={collectionId}
                                onClick={() => handleCollectionClick(collectionId)}
                                className="clickable-collection"
                            >
                                {collectionName}
                            </div>
                        ))}
                    </div>
                </div>
                {filteredProducts.length == 0 && 
                    <div className= "empty-products-view">
                        <p>Brak produktów do wyświetlenia, zmień proszę kryteria</p>
                    </div>}
                <div className="products-view">
                    {filteredProducts.map((product) => (
                        <a className="product-link" id={product.id} href={product.url} key={product.id}>
                            <div className="shop-product-container" id={product.id}>
                                <img className="shop-thumbnail" src={product.thumb} alt={product.title}></img>
                                <h2 className="product-info">{product.title}</h2>
                                <p className="product-info product-price"> Cena od: {product.price} PLN </p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>}
        </div>
    )
}
export default Shop;