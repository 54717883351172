import React, { useState, useEffect } from "react";
import axios from "axios";
import toolkit from "../../utils";
import "./Admin.css";
import Login from '../login/Login';
import Navbar from '../../components/navbar/Navbar';
import EditableList from '../../components/admin-list/edit-list';
import EditView from '../../components/edit-view/edit-view';

const adminConfig = [
    { id: 1, href: '/listProduct', text: 'Produkty' },
    { id: 2, href: '/listCategories', text: 'Kategorie' },
    { id: 3, href: '/listCollection', text: 'Kolekcje' },
    { id: 4, href: '/listDelivery', text: 'Wysyłka' },
    { id: 5, href: '/listOptions', text: 'Opcje' },
    { id: 6, href: '/listWood', text: 'Próbki drewna' },
    { id: 7, href: '/listGallery', text: 'Realizacje' },
]
function Admin() {
    const [isProductList, setProductsView] = useState(false);
    const [isCategoriesList, setCategoriesView] = useState(false);
    const [isCollectionsList, setCollectionsView] = useState(false);
    const [isDeliveryList, setDeliveryView] = useState(false);
    const [isOptionsList, setOptionsView] = useState(false);
    const [isVariantsList, setVariantsView] = useState(false);
    const [isWoodList, setWoodView] = useState(false);
    const [isGalleryList, setGalleryView] = useState(false);
    const [isEditView, setEditView] = useState(false);

    axios.defaults.withCredentials = true
    const [loggedIn, setloggedinView] = useState(false);

    // handle session
    useEffect(() => {
        handleSession()
    });

    // handle urls and views
    useEffect(() => {
        if (window.location.href.includes("listProduct")) {
            setProductsView(true)
        }
        if (window.location.href.includes("listCategories")) {
            setCategoriesView(true)
        }
        if (window.location.href.includes("listCollection")) {
            setCollectionsView(true)
        }
        if (window.location.href.includes("listDelivery")) {
            setDeliveryView(true)
        }
        if (window.location.href.includes("listOptions")) {
            setOptionsView(true)
        }
        if (window.location.href.includes("listVariants")) {
            setVariantsView(true)
        }
        if (window.location.href.includes("listWood")) {
            setWoodView(true)
        }
        if (window.location.href.includes("listGallery")) {
            setGalleryView(true)
        }
        if (window.location.href.includes("edit")) {
            setEditView(true)
        }

    }, []);

    const handleSession = async () => {
        let isLogged = await toolkit.isLoggedAsAdmin();
        setloggedinView(isLogged);
    };

    function handleLogout() {
        axios({
            method: "get",
            url: `${toolkit.setReactAppApi("logout")}`,
            headers: { "content-type": "application/json" },
        })
            .then(result => {
                if (result.status === 200) {
                    localStorage.setItem("isAuth", false)
                    setloggedinView(false);
                    window.location.reload(true);
                }
            })
    };

    return (
        <div className="admin">
            {!loggedIn && <div className="admin"><Login></Login></div>}
            {loggedIn && <div className="admin">

                <Navbar config={adminConfig} />
                <button id="logout-button" onClick={handleLogout}>
                    Wyloguj
                </button>

                <div className="admin-content">
                    {isProductList && <div className="list-admin">
                        <h1> Produkty</h1>
                        <EditableList type="product" /></div>}
                    {isCategoriesList && <div className="list-admin">
                    <h1> Kategorie</h1>
                        <EditableList type="category" /></div>}
                    {isCollectionsList && <div className="list-admin">
                        <h1> Kolekcje</h1>
                        <EditableList type="collection" /></div>}
                    {isDeliveryList && <div className="list-admin">
                    <h1> Wysyłka</h1>
                        <EditableList type="delivery" /></div>}
                    {isOptionsList && <div className="list-admin">
                    <h1> Opcje produktu</h1>
                        <EditableList type="options" /></div>}
                    {isWoodList && <div className="list-admin">
                    <h1> Próbki</h1>
                        <EditableList type="wood" /></div>}
                    {isGalleryList && <div className="list-admin">
                    <h1> Realizacje</h1>
                        <EditableList type="gallery" /></div>}
                    {isEditView && <div className="edit-view">
                        <h1> Edycja elementu </h1>
                        <EditView />
                    </div>}
                </div>
            </div>}
        </div >
    );
}
export default Admin;