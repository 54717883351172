import React, { useEffect, useState, useRef } from "react";
import toolkit from "../../utils";
import "./cart.css";
import axios from "axios";
import GDPRCheckbox from "../../components/gdprCheckbox";
import RulesConsentCheckbox from "../../components/rulesConsentCheckbox";

function Cart() {
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setCartStatus] = useState(true);
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [cartHash, setCartHash] = useState(localStorage.getItem("CartHash"));
  const [mailSent, setmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [showInvoiceData, setShowInvoiceData] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSendingVisible, setSendingVisibility] = useState(false);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [redGDPRCheckbox, setGDPRCheckboxToRed] = useState(false)
  const [redRulesCheckbox, setRulesCheckboxToRed] = useState(false)
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [validField, setFieldValidity] = useState({
    name: true,
    lastName: true,
    street: true,
    postalCode: true,
    city: true,
    phone: true,
    email: true,
    msg: true,
    invoiceCompanyName: true,
    invoiceStreet: true,
    invoicePostalCode: true,
    invoiceCity: true,
    NIP: true,
    gdprConsent: true,
    rulesConsent: true,
  });
  const [formData, setFormData] = useState({
    to: "agnieszka.j.czyzewska@gmail.com",
    subject: "Wiadomość z koszyka",
  });
  const successMessage =
    "Dziękujemy za złożone zamówienie. Odezwiemy się najszybciej jak to możliwe";
  const errorMessage =
    "Wystąpił błąd. Spróbuj ponownie lub wyślij maila na adres gmo@gmo-design.pl";
  const fieldsConfig = [
    {
      id: 1,
      label: "Imię",
      fieldName: "name",
      type: "text",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16",
    },
    {
      id: 2,
      label: "Nazwisko",
      fieldName: "lastName",
      type: "text",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16",
    },
    {
      id: 3,
      label: "Ulica",
      fieldName: "street",
      type: "text",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16",
    },
    {
      id: 4,
      label: "Kod pocztowy",
      fieldName: "postalCode",
      type: "text",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16 postal-code",
    },
    {
      id: 5,
      label: "Miasto",
      fieldName: "city",
      type: "text",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16 city",
    },
    {
      id: "phone",
      label: "Numer telefonu",
      fieldName: "phone",
      type: "number",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16",
    },
    {
      id: 7,
      label: "Adres e-mail",
      fieldName: "email",
      type: "email",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16",
    },
    {
      id: 8,
      label: "Uwagi do zamówienia (opcjonalnie)",
      fieldName: "msg",
      type: "textarea",
      placeholder: "",
      isRequired: false,
      className: "cart-input w3-padding-16",
    },
  ];
  const companyFieldsConfig = [
    {
      id: 11,
      label: "Nazwa firmy",
      fieldName: "invoiceCompanyName",
      type: "text",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16",
    },
    {
      id: 12,
      label: "Ulica",
      fieldName: "invoiceStreet",
      type: "text",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16",
    },
    {
      id: 13,
      label: "Kod pocztowy",
      fieldName: "invoicePostalCode",
      type: "text",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16",
    },
    {
      id: 14,
      label: "Miasto",
      fieldName: "invoiceCity",
      type: "text",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16",
    },
    {
      id: 15,
      label: "NIP",
      fieldName: "NIP",
      type: "number",
      placeholder: "",
      isRequired: true,
      className: "cart-input w3-padding-16",
    },
  ];
  const checkboxRef = useRef(null);
  const [selectedNumber, setSelectedNumber] = useState(1);

  const toggleInvoiceData = () => {
    setShowInvoiceData((prevShowInvoiceData) => !prevShowInvoiceData);
  };

  const handleQuantityChange = async (item, event) => {
    event.preventDefault();
    const newNumber = parseInt(event.target.value, 10);
    setSelectedNumber(newNumber);
    try {
      const updatedItem = {
        ...item,
        quantity: Math.max(1, Math.floor(event.target.value)),
      };
      setCartItems((prevCartItems) => {
        const updatedCartItems = prevCartItems.map((cartItem) =>
          cartItem === item ? updatedItem : cartItem
        );
        return updatedCartItems;
      });
      await toolkit.updateCart(updatedItem.ID, updatedItem.quantity);
    } catch (error) {
      console.error("Error updating cart quantity:", error);
    }
  };

  const removeProduct = async (item, event) => {
    event.preventDefault();
    const optionIDToRemove = item.ID;

    try {
      await toolkit.updateCart(optionIDToRemove, 0);
      const updatedCartItems = await toolkit.getCartItems(cartHash);
      setCartItems(updatedCartItems);
      if (updatedCartItems.length === 0) {
        setIsEmpty(true);
      }
      window.location.reload();
    } catch (error) {
      console.error("Error removing product from cart:", error);
    }
  };

  const changeColorOnValidation = (fieldID) => {
    const element = document.getElementById(fieldID);
    if (!element.validity.valid) {
      element.classList.add("red");
    } else {
      element.classList.remove("red");
    }
  };

  const handleGDPRColors = () => {
    if (!formData.gdprConsent) {
      setGDPRCheckboxToRed(true);
    }
    else {
      setGDPRCheckboxToRed(false);
    }
  };

  const handleRulesConsent = () => {
    if (!formData.rulesConsent) {
      setRulesCheckboxToRed(true);
    }
    else {
      setRulesCheckboxToRed(false);
    }
  };

  const validateForm = () => {
    const form = document.getElementById("delivery-form");
    let isFormValid = form.checkValidity();
    handleGDPRColors();
    handleRulesConsent();
    const phoneField = document.getElementById("phone");
    const phoneRegex = /^\d{9}$/; // Regex for 9 digits
    if (!phoneRegex.test(phoneField.value)) {
      const errorMessage = "Numer telefonu musi składać się z 9 cyfr.";
      setPhoneErrorMessage(errorMessage);
      isFormValid = false;
      setFieldValidity((prevValidity) => ({
        ...prevValidity,
        // eslint-disable-next-line no-useless-computed-key
        ["phone"]: false,
      }));
    } else {
      setPhoneErrorMessage("");
      setFieldValidity((prevValidity) => ({
        ...prevValidity,
        // eslint-disable-next-line no-useless-computed-key
        ["phone"]: true,
      }));
    }
    if (showInvoiceData) {
      const companyForm = document.getElementById("company-form");
      isFormValid = companyForm.checkValidity() && isFormValid;
    }
    setFormIsValid(isFormValid);
    return isFormValid;
  };

  const handleChange = (e, field) => {
    const value = field.type === "checkbox" ? e.target.checked : e.target.value;
    handleGDPRColors();
    handleRulesConsent();
    validateForm();
    setFormData((prevData) => ({
      ...prevData,
      [field.fieldName]: value,
    }));

    setFieldValidity((prevValidity) => ({
      ...prevValidity,
      [field.fieldName]:
        field.type === "checkbox" ? e.target.checked : e.target.validity.valid,
    }));

    if (field.isRequired) {
      changeColorOnValidation(field.id);
    }
  };

  const setClassNameForField = (className, field) => {
    let isValidField = validField[field.fieldName];
    let classToBeSet = isValidField ? className : className + " red";
    return classToBeSet;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setSendingVisibility(true);
    let isFormValid = validateForm();
    if (isFormValid && formData.gdprConsent && formData.rulesConsent) {
      try {
        const result = await axios.post(toolkit.setReactAppApi("submit-cart"), {
          formData,
          cartItems,
          cartTotal
        }, {
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (result.status === 200) {
          setmailSent(true);
          setFormIsValid(true);
          setError(false);
          handleGDPRColors();
          handleRulesConsent();
          setPopupVisibility(true);
          setTimeout(() => {
            setPopupVisibility(false);
          }, 1000);
          localStorage.removeItem("CartHash");
          localStorage.removeItem("CartQuantity");
          setIsEmpty(true);
          setCartHash(null);
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
      }
    } else {
      fieldsConfig.forEach((field) => {
        if (field.isRequired) {
          const element = document.getElementById(field.id);
          setFieldValidity((prevValidity) => ({
            ...prevValidity,
            // eslint-disable-next-line no-useless-computed-key
            [field]: element.validity.valid,
          }));
          changeColorOnValidation(field.id);
        }
      });
      if (showInvoiceData) {
        companyFieldsConfig.forEach((field) => {
          if (field.isRequired) {
            const element = document.getElementById(field.id);
            setFieldValidity((prevValidity) => ({
              ...prevValidity,
              [field]: element.validity.valid,
            }));
            changeColorOnValidation(field.id);
          }
        });
      }
    }
    setSendingVisibility(false);
    setFormSubmitted(true);
  };

  // Update cartHash state when the value in localStorage changes
  useEffect(() => {
    const handleStorageChange = () => {
      setCartHash(localStorage.getItem("CartHash"));
    };

    // Listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Load cart data
  useEffect(() => {
    const loadCart = async () => {
      if (cartHash != null) {
        let cartItemsFromDB = await toolkit.getCartItems(cartHash);
        if (cartItemsFromDB) {
          await Promise.all(
            cartItemsFromDB.map(async (item) => {
              const price = await toolkit.getCartProductValues(
                item.product_id,
                item.optionsSelected
              );
              item.price = price;
              const thumbnailImages = await toolkit.getProductImages(
                item.product_id
              );
              if (
                thumbnailImages.length > 0 &&
                thumbnailImages[0] !== undefined
              ) {
                item.thumbnail = thumbnailImages[0].image_path;
              }
              item.url = "/product?id=" + item.product_id;
            })
          );
          return cartItemsFromDB;
        }
      } else return 0;
    };

    const preloadData = async () => {
      await loadCart().then((cartItemsFromDB) => {
        if (cartItemsFromDB !== undefined && cartItemsFromDB.length > 0) {
          setCartItems(cartItemsFromDB);
          setIsEmpty(false);
          setCartStatus(false);
        } else {
          setIsEmpty(true);
          setCartStatus(false);
        }
      });
      await toolkit.countAndUpdateCartQuantity(cartHash);
    };
    preloadData();
  }, [cartHash]);

  // Set cart view
  useEffect(() => {
    if (cartItems.length > 0) {
      if (parseInt(localStorage.getItem("CartQuantity")) > 0) {
        setIsEmpty(false);
        setCartStatus(false);
      } else {
        setIsEmpty(true);
      }
    }
  }, [cartItems]);

  // Count total to pay
  useEffect(() => {
    const itemTotalPrices = cartItems.map((item) => item.price * item.quantity);
    const totalPrice = itemTotalPrices.reduce((acc, curr) => acc + curr, 0);
    setCartTotal(totalPrice);
  }, [cartItems]);

  useEffect(() => {
    handleGDPRColors();
    handleRulesConsent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <div className="cart">
      {isLoading && (
        <div className="cart-empty">
          <div className="divider" />
          <p>Wczytujemy Twój koszyk.</p>
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
          <div className="cart-back-button">
            <a className="cart-backward" href="/">
              Wróć do sklepu
            </a>
          </div>
        </div>
      )}

      {isSendingVisible && (
        <div className="cart-sending">
          <div className="spinner-container spinner-sending-container">
            <div className="spinner"></div>
          </div>
        </div>
      )}

      {!isLoading && isEmpty && (
        <div className="cart-empty">
          <div className="divider" />
          <p>Twój koszyk aktualnie jest pusty.</p>
          <div className="cart-back-button">
            <a className="cart-backward" href="/">
              Wróć do sklepu
            </a>
          </div>
        </div>
      )}

      {!isLoading && !isEmpty && (
        <div className="cart-with-products">
          <div className="cart-grid">
            <div className="cart-form">
              <form className="cart-form" action="#">
                <h2 className="cart-header">Koszyk</h2>
                <table className="shop_table  table-responsive" cellSpacing="0">
                  <thead>
                    <tr>
                      <th className="product-thumbnail">
                        <span className="screen-reader-text">Miniatura</span>
                      </th>
                      <th className="product-name">Produkt</th>
                      <th className="cart-product-options">Opcje</th>
                      <th className="brutto">Cena brutto</th>
                      <th className="product-quantity">Ilość</th>
                      <th className="product-subtotal">Kwota brutto</th>
                      <th className="remove-product"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item, index) => (
                      <tr key={index} className="cart_item">
                        <td className="product-thumbnail">
                          <a href={item.url}>
                            <img
                              decoding="async"
                              width="58"
                              height="72"
                              src={item.thumbnail}
                              alt=""
                            ></img>
                          </a>
                        </td>

                        <td className="product-name" data-title="Produkt">
                          <a href={item.url}>{item.product}</a>
                        </td>
                        <td className="cart-product-options" data-title="Opcje">
                          <p>
                            {" "}
                            {JSON.stringify(item.optionsSelected)
                              .replace(/[{}"]/g, "")
                              .replace(/:/g, ": ")
                              .replace(/,/g, ", ")
                              .replace(/\\/g, "")}
                          </p>
                        </td>

                        <td className="brutto" data-title="Cena">
                          <span className="price-amount amount">
                            {item.price}
                          </span>
                        </td>

                        <td className="product-count" data-title="Ilość">
                          <select
                            id="numberSelector"
                            value={selectedNumber}
                            onChange={(event) =>
                              handleQuantityChange(item, event)
                            }
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                        </td>

                        <td className="product-subtotal" data-title="Kwota">
                          <span className="price-amount amount">
                            {item.price * item.quantity}
                          </span>
                        </td>

                        <td className="remove-product" data-title="Remove">
                          <input
                            className="remove-button"
                            onClick={(e) => removeProduct(item, e)}
                            defaultValue="X"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>

              <div className="cart-total">
                <h2>Suma do zapłaty: {cartTotal} PLN</h2>
              </div>

              <div className="delivery-total">
                {cartItems.length === 1 && (
                  <p>Szacowana kwota dostawy: {cartItems[0].delivery} PLN</p>
                )}
                {cartItems.length !== 1 && (
                  <p>
                    Ze względu na kilka produktów w koszyku szacowana kwota
                    dostawy zostanie przedstawiona w potwierdzeniu zamówienia.
                  </p>
                )}
              </div>
              <div className="checkbox-container">
                <GDPRCheckbox
                  id="gdprConsent"
                  checked={formData.gdprConsent}
                  onChange={(e) =>
                    handleChange(e, {
                      fieldName: "gdprConsent",
                      type: "checkbox",
                    })
                  }
                  red={redGDPRCheckbox}
                />
                <RulesConsentCheckbox
                  id="rulesConsent"
                  checked={formData.rulesConsent}
                  onChange={(e) =>
                    handleChange(e, {
                      fieldName: "rulesConsent",
                      type: "checkbox",
                    })
                  }
                  red={redRulesCheckbox}
                />
              </div>
            </div>
            <div id="delivery-data">
              <div className="contact-form__header">
                <h2 className="contact-form__header">Adres do wysyłki</h2>
              </div>
              <div className="contact-form__container">
                <div>
                  <form id="delivery-form" action="#">
                    {fieldsConfig &&
                      fieldsConfig.map((field) => (
                        <React.Fragment key={field.id}>
                          <label>{field.label}</label>
                          {field.type !== "textarea" ? (
                            <input
                              id={field.id}
                              type={field.type}
                              className={setClassNameForField(
                                field.className,
                                field
                              )}
                              placeholder={field.placeholder}
                              defaultValue={formData[field.fieldName]}
                              required={field.isRequired}
                              onChange={(e) => handleChange(e, field)}
                            />
                          ) : (
                            <textarea
                              id={field.id}
                              className={setClassNameForField(
                                field.className,
                                field
                              )}
                              placeholder={field.placeholder}
                              onChange={(e) => handleChange(e, field)}
                              defaultValue={formData[field.fieldName]}
                            />
                          )}
                          {field.isRequired &&
                            formSubmitted &&
                            !validField[field.fieldName] &&
                            field.fieldName !== "phone" && (
                              <p className="validation-message small">
                                {" "}
                                Pole jest niepoprawnie wypełnione
                              </p>
                            )}
                          {field.fieldName === "phone" &&
                            !validField["phone"] && (
                              <p className="validation-message small">
                                {phoneErrorMessage}
                              </p>
                            )}
                        </React.Fragment>
                      ))}
                  </form>
                </div>
              </div>
              <div className="company-data">
                <p>
                  <label>
                    Chcę fakturę na firmę{" "}
                    <input
                      type="checkbox"
                      onChange={toggleInvoiceData}
                      ref={checkboxRef}
                    />
                  </label>
                </p>
                <form id="company-form" action="#">
                  {showInvoiceData &&
                    companyFieldsConfig.map((field) => {
                      return (
                        <React.Fragment key={field.id}>
                          {field.type !== "textarea" ? (
                            <React.Fragment>
                              <label>{field.label}</label>
                              <input
                                id={field.id}
                                type={field.type}
                                className={setClassNameForField(
                                  field.className,
                                  field
                                )}
                                placeholder={field.placeholder}
                                defaultValue={field.name}
                                onChange={(e) => handleChange(e, field)}
                                required
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <label>{field.label}</label>
                              <textarea
                                className={field.className}
                                placeholder={field.placeholder}
                                onChange={(e) =>
                                  handleChange(e, field.fieldName)
                                }
                                defaultValue={field.name}
                              />
                            </React.Fragment>
                          )}
                          {showInvoiceData &&
                            field.isRequired &&
                            formSubmitted &&
                            !validField[field.fieldName] && (
                              <p className="validation-message">
                                {" "}
                                Pole jest niepoprawnie wypełnione
                              </p>
                            )}
                        </React.Fragment>
                      );
                    })}
                </form>
              </div>
            </div>
          </div>
          {formSubmitted && !formIsValid && (
            <div
              className="validation-message"
              id="validation-empty-field-message"
            >
              Nie wszystkie pola zostały wypełnione. Proszę sprawdź formularz.
            </div>
          )}
          <div className="order-timeline">
            <p>
              Po otrzymaniu zamówienia, skontaktujemy się z Tobą w ciągu 72h w
              celu potwierdzenia sposobu dostawy i terminu realizacji.
            </p>
            <p>
              Po ustaleniu szczegółów, wyślemy Ci mail z potwierdzeniem, danymi
              do przelewu oraz datą dostawy.
            </p>
          </div>
          <input
            className="submit-button send-cart-button"
            type="submit"
            onClick={(e) => {
              handleFormSubmit(e);
            }}
            value="Wyślij"
          />
          {isPopupVisible && (
            <div className="popup-container">
              <div className="popup cart-popup">
                {mailSent && <div className="cart-popup success">{successMessage}</div>}
                {error && <div className="error">{errorMessage}</div>}
              </div>
            </div>
          )}
        </div>
      )
      }
    </div >
  );
}

export default Cart;
