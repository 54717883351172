
import React, { useEffect, useState } from "react";
import "./App.css";
import Footer from './components/footer/Footer';
import Gallery from "./components/gallery/Gallery";
import ProbkiGallery from "./components/gallery/ProbkiGallery";
import Navbar from './components/navbar/Navbar';
import Cart from "./pages/cart/Cart";
import Home from './pages/home/Home';
import ProductDetails from './pages/product-details/ProductDetails';
import HowToCarePage from './pages/regular-pages/HowToCare';
import PrivacyPage from './pages/regular-pages/PrivacyPage';
import RulesPage from './pages/regular-pages/RulesPage';
import OrderRulesPage from './pages/regular-pages/Zamowienie';
import Shop from './pages/shop/Shop';

const config = [
  { id: 1, href: './home', iclass: 'fa fa-home gmo-xxlarge', text: 'GŁÓWNA' },
  { id: 2, href: './shop', iclass: 'fa fa-gift gmo-xxlarge', text: 'SKLEP' },
  { id: 3, href: './about', iclass: 'fa fa-user gmo-xxlarge', text: 'O NAS' },
  { id: 4, href: './gallery', iclass: 'fa fa-eye gmo-xxlarge', text: 'REALIZACJE' },
  { id: 5, href: './probki', iclass: 'fa fa-eye gmo-xxlarge', text: 'WZORNIK DREWNA' },
  { id: 6, href: './contact', iclass: 'fa fa-envelope gmo-xxlarge', text: 'KONTAKT' },
]

function App() {

  const [isGallery, setgalleryView] = useState(false);
  const [isProbki, setprobkiView] = useState(false);
  const [isProduct, setProductDetailsView] = useState(false);
  const [isCart, setCartView] = useState(false);
  const [isShop, setShopView] = useState(false);
  const [isPrivacy, setPrivacyView] = useState(false);
  const [isRules, setRulesView] = useState(false);
  const [isOrderRules, setOrderRulesView] = useState(false);
  const [isHowToCare, setHowToCare] = useState(false);



  useEffect(() => {
    if (window.location.href.includes("gallery")) {
      setgalleryView(true)
    }
    if (window.location.href.includes("cart")) {
      setCartView(true)
    }
    if (window.location.href.includes("shop")) {
      setShopView(true)
    }
    if (window.location.href.includes("probki")) {
      setprobkiView(true)
    }
    if (window.location.href.includes("product")) {
      setProductDetailsView(true)
    }
    if (window.location.href.includes("polityka-prywatnosci")) {
      setPrivacyView(true)
    }
    if (window.location.href.includes("regulamin")) {
      setRulesView(true)
    }
    if (window.location.href.includes("zamowienie")) {
      setOrderRulesView(true)
    }
    if (window.location.href.includes("pielegnacja-drewna")) {
      setHowToCare(true)
    }
  }, [])
  return (

    <main className="Main">
      <Navbar config={config} />
      {isGallery && <div className="gallery">
        <div className="gmo-padding-large" id="gallery">
          <h1 className="gallery-heading"> Realizacje</h1>
          <h3 className="gallery-heading"> Kliknij na zdjęcie aby zobaczyć opis</h3>
          <Gallery></Gallery>
        </div>
      </div>}
      {isProbki && <div className="gallery">
        <div className="gmo-padding-large" id="gallery">
          <h1 className="gallery-heading"> Próbki drewna</h1>
          <ProbkiGallery> </ProbkiGallery>
        </div>
      </div>}
      {isProduct && <div className="product"><ProductDetails></ProductDetails></div>}
      {isShop && <div className="cart"><Shop></Shop></div>}
      {isCart && <div className="cart"><Cart></Cart></div>}
      {isPrivacy && <div className="page"><PrivacyPage></PrivacyPage></div>}
      {isRules && <div className="page"><RulesPage></RulesPage></div>}
      {isOrderRules && <div className="page"><OrderRulesPage></OrderRulesPage></div>}
      {isHowToCare && <div className="page"><HowToCarePage></HowToCarePage></div>}
      {!isShop && !isCart && !isGallery && !isProbki && !isProduct && !isPrivacy
        && !isRules && !isOrderRules && !isHowToCare && <div className="home"> <Home></Home></div>}
      <Footer></Footer>
    </main>
  );
}

export default App;