import React from 'react';
import "./regular-pages.css";

function PrivacyPage() {

    return (
        <div className="privacy">
            <h1 className = "privacy" >Polityka Prywatności</h1>
            <h2>KLAUZULA INFORMACYJNA</h2>
            <p>Na podstawie Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
                27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „Rozporządzeniem”, informujemy, że:</p>
            <h2>1. Administrator danych</h2>
            <p>Administratorem danych osobowych gmo-design.pl (dalej Administrator), czyli odpowiedzialnym za zapewnienie bezpieczeństwa Twoim danym osobowym jest:
                GMO Design Maciej Czyżewski
                NIP: 9442068011</p>
            <h2>2. Kategorie danych osobowych</h2>
            <p>„GMO Design” przetwarza następujące kategorie danych osobowych:
                dane identyfikacyjne, dane adresowe oraz dane kontaktowe.</p>
            <h2>3. Cel przetwarzania danych i podstawy prawne</h2>
            <div><span >Dane osobowe mogą być przetwarzane przez „GMO Design” w następujących celach:</span>
                <span > 1 realizacji przez „GMO Design” czynności wykonania oraz wysłania zamówienia</span></div>
            <div><span>2 realizacji obowiązków&nbsp;„GMO Design”&nbsp;przewidzianych prawem, w tym w szczególności wystawiania i przechowywania faktur oraz dokumentów księgowych, oraz dla celów archiwizacyjnych.</span></div>
            <div></div>
            <h2>4. Udostępnienie danych osobowych oraz odbiorca danych osobowych</h2>
            <div>“GMO Design” może przekazywać Dane osobowe następującym osobom trzecim:</div>
            <div>1.operatorom pocztowym i kurierom;</div>
            <div>2. organom uprawnionym do otrzymania Pani/Pana danych na podstawie przepisów prawa</div>
            <h2>5. Przekazywanie danych osobowych do państwa trzeciego</h2>
            <p>Dane nie są przekazywane.</p>
            <h2>6. Okres przechowywania danych osobowych</h2>
            <p>Okres przetwarzania Państwa danych osobowych jest uzależniony od celu, w jakim dane są przetwarzane. :</p>
            <p>1. przepisy prawa, które mogą obligować do przetwarzania danych przez określony czas (</p>
            <p>2. okres przez jaki są świadczone usługi;</p>
            <p>3. okres, który jest niezbędny do obrony interesów administratora;</p>
            <p>4. okres na jaki została udzielona zgoda.</p>
            <h2>7. Przysługujące prawa</h2>
            <p>W związku z przetwarzaniem przez „GMO Design” Pani/Pana danych osobowych, przysługuje Pani/Panu:
                1. prawo dostępu do danych osobowych,
                2. prawo do sprostowania danych osobowych,
                3. prawo usunięcia danych osobowych (prawo do bycia zapomnianym),
                4. prawo do ograniczenia przetwarzania danych osobowych,
                5. prawo do przenoszenia danych do innego administratora,
                6. prawo do wniesienia sprzeciwu wobec przetwarzania danych, w tym profilowania, oraz na potrzeby marketingu bezpośredniego, w tym profilowania,
                7. prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy uzna Pani/Pan, że przetwarzanie danych osobowych narusza przepisy Rozporządzenia.</p>
            <h2>8. Źródło pochodzenia danych</h2>
            <p>Dane osobowe pochodzą od klientów składających zamówienie.</p>
            <h2>9. Wymóg podania danych</h2>
            <p>Podanie danych osobowych jest konieczne w celu określonym w pkt 3</p>
            <h2>10. Zautomatyzowane podejmowanie decyzji, w tym profilowanie</h2>
            <p>Dane osobowe nie będą przetwarzane w sposób zautomatyzowany, w tym w celu profilowania.</p>
            <h2>11. Subskrypcja Newslettera</h2>
            <p>W przypadku subskrypcji newslettera oraz wyrażenia zgody na jego otrzymywanie w procesie rejestracji lub składania zamówienia prosimy jedynie o podanie adresu e-mail wraz z imieniem, na który przesyłane będę informacje handlowe od Administratora. Z subskrypcji w newsletterze z w każdej chwili możesz zrezygnować klikając link anulujący subskrypcję i znajdujący się w stopce każdego newslettera.</p>
            <p>Informujemy również, że :
                Administrator dokłada wszelkich starań, aby zapewnić wszelkie środki fizycznej, technicznej
                i organizacyjnej ochrony danych osobowych przed ich przypadkowym czy umyślnym zniszczeniem, przypadkową utratą, zmianą, nieuprawnionym ujawnieniem, wykorzystaniem czy dostępem, zgodnie ze wszystkimi obowiązującymi przepisami</p>
            <p>Administrator Bezpieczeństwa Informacji
                GMO-Design Maciej Czyżewski</p>
        </div>
    );
}

export default PrivacyPage;