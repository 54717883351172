import axios from "axios";
import { setEndpointReactAppApi, isLoggedAsAdmin } from "./endpoint";

async function calculateVariantsValue(product, selectedVariants) {
  let endpoint = `${toolkit.setReactAppApi("calculateProductValue")}`;
  try {
    let result = await axios({
      method: "post",
      url: endpoint,
      headers: { "content-type": "application/json" },
      data: {
        product,
        selectedVariants
      }
    })
    if (result.status === 200) {
      return result.data
    }
  }
  catch (error) {
    console.log("Error: " + error);
  }
};

async function getData(endpoint) {
  try {
    let result = await axios({
      method: "post",
      url: `${toolkit.setReactAppApi(endpoint)}`,
      headers: { "content-type": "application/json" },
    })
    if (result.status === 200) {
      return result.data
    }
  }
  catch (error) {
    console.log("Error: " + error);
  }
};

async function getProductData(endpoint, productId) {
  try {
    let result = await axios({
      method: "post",
      url: endpoint,
      headers: { "content-type": "application/json" },
      data: {
        productId: productId
      }
    })
    if (result.status === 200) {
      return result.data
    }
  }
  catch (error) {
    console.log("Error: " + error);
  }
};

const toolkit = {

  setReactAppApi(endpoint) {
    return setEndpointReactAppApi(endpoint);
  },

  async isLoggedAsAdmin() {
    return isLoggedAsAdmin()
  },
  // To be deprecated
  async getEndpointData(endpoint, columns, where) {
    try {
      let result = await axios({
        method: "post",
        url: `${toolkit.setReactAppApi("data")}`,
        headers: { "content-type": "application/json" },
        data: {
          "endpoint": endpoint,
          "columns": columns,
          "where": where
        }
      })
      if (result.status === 200) {
        return result.data
      }
    }
    catch (error) {
      console.log("Error: " + error);
    }
  },

  async getShopProducts() {
    let data = await getData("productsList");
    return data
  },

  async getCategories() {
    let data = await getData("categories");
    if (data !== undefined) {
      const object = data.reduce((acc, currentValue) => {
        acc[currentValue.id] = currentValue.name;
        return acc;
      }, {});
      return object
    } else
      return undefined
  },

  async getCollections() {
    let data = await getData("collections");
    const object = data.reduce((acc, currentValue) => {
      acc[currentValue.id] = currentValue.name;
      return acc;
    }, {});
    return object
  },

  async getDelivery() {
    let data = await getData("delivery");
    return data;
  },

  async getProductDetails(productId) {
    let endpoint = `${toolkit.setReactAppApi("product")}`;
    let data = await getProductData(endpoint, productId);
    return data
  },

  async getProductImages(productId) {
    let endpoint = `${toolkit.setReactAppApi("productGallery")}`;
    let data = getProductData(endpoint, productId);
    return data;
  },

  async getVariants(optionId) {
    let endpoint = `${toolkit.setReactAppApi("variants")}`;
    let data = await toolkit.getVariantsData(endpoint, optionId);
    return data;
  },

  async updateCart(cartId, quantity) {
    let endpoint = `${toolkit.setReactAppApi("updateQuantity")}`;
    try {
      let result = await axios({
        method: "post",
        url: endpoint,
        headers: { "content-type": "application/json" },
        data: {
          cartId,
          quantity
        }
      })
      if (result.status === 200) {
        return result.data
      }
    }
    catch (error) {
      console.log("Error: " + error);
    }
  },

  async getOptions(optionId) {
    try {
      let result = await axios({
        method: "post",
        url: `${toolkit.setReactAppApi("productOptions")}`,
        headers: { "content-type": "application/json" },
        data: {
          option_id: optionId
        }
      })
      if (result.status === 200) {
        return result.data
      }
    }
    catch (error) {
      console.log("Error: " + error);
    }
  },

  async getVariantsData(endpoint, optionId) {
    try {
      let result = await axios({
        method: "post",
        url: endpoint,
        headers: { "content-type": "application/json" },
        data: {
          option_id: optionId
        }
      })
      if (result.status === 200) {
        return result.data
      }
    }
    catch (error) {
      console.log("Error: " + error);
    }
  },

  async addToCart(productObject, cartHash, quantity) {
    let endpoint = `${toolkit.setReactAppApi("addToCart")}`;
    try {
      await axios({
        method: "post",
        url: endpoint,
        headers: { "content-type": "application/json" },
        data: {
          productObject,
          cartHash,
          quantity
        }
      })
    }
    catch (error) {
      console.log("Error: " + error);
    }
  },

  async getCartItems(cartHash) {
    if (typeof cartHash !== undefined) {
      let endpoint = `${toolkit.setReactAppApi("getCart")}`;
      try {
        let result = await axios({
          method: "post",
          url: endpoint,
          headers: { "content-type": "application/json" },
          data: {
            cartHash: cartHash
          }
        })
        if (result.status === 200) {
          return result.data
        }
      }
      catch (error) {
        console.log("Error: " + error);
      }
    }
  },

  async getCartProductValues(productId, selectedVariants) {
    let value = await calculateVariantsValue(productId, selectedVariants);
    return value;
  },

  async countAndUpdateCartQuantity(cartHash) {
    let cartQuantity = 0;
    let cartItems = await toolkit.getCartItems(cartHash)
    if (cartItems) {
      for (let i = 0; i < cartItems.length; i++) {
        cartQuantity = cartQuantity + cartItems[i].quantity
      }
    }

    localStorage.setItem("CartQuantity", cartQuantity);
    return cartQuantity;
  },

  async updateCartQuantity(cartQuantity) {
    if (localStorage.getItem("CartHash") !== null) {
      if (cartQuantity === undefined) {
        cartQuantity = localStorage.getItem("CartQuantity");
      }
    }
    else cartQuantity = 0;
    let cartToChange = document.getElementById("cart-item-number");
    if (cartToChange !== null) {
      cartToChange.textContent = cartQuantity;
    }
  },

  async getPortfolioImages() {
    let endpoint = toolkit.setReactAppApi("portfolioGallery")
    try {
      let result = await axios({
        method: "post",
        url: endpoint,
        headers: { "content-type": "application/json" },
      })
      if (result.status === 200) {
        return result.data
      }
    }
    catch (error) {
      console.log("Error: " + error);
    }
  },

  async getProbkiImages() {
    // Assume that probki is always on id 1 in databes
    let data = await toolkit.getVariants("1");
    let imagesArray = [];
    data.forEach((image, index) => {
      let imageToPutToArray = {
        id: index,
        image_path: image.path,
        thumbs_path: image.path,
        label: image.label
      }
      imagesArray.push(imageToPutToArray);
    });
    return imagesArray
  },

  async deleteItem(type, id) {
    try {
      let result = await axios({
        method: "delete",
        url: toolkit.setReactAppApi(`delete?type=${type}&id=${id}`),
        headers: { "content-type": "application/json" },
      })
      if (result.status === 200) {
        return true
      }
      else return false;
    }
    catch (error) {
      console.log("Error: " + error);
    }
  },

  async deleteImage(type, id) {
    try {
      let result = await axios({
        method: "delete",
        url: toolkit.setReactAppApi(`deleteImage?type=${type}&id=${id}`),
        headers: { "content-type": "application/json" },
      })
      if (result.status === 200) {
        return true
      }
      else return false;
    }
    catch (error) {
      console.log("Error: " + error);
    }
  },

  async updateItem(type, id, dataToSet) {
    try {
      const result = await axios.patch(
        toolkit.setReactAppApi(`update?type=${type}&id=${id}`),
        dataToSet,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (result.status === 200) {
        return result.data.id;
      } else {
        return;
      }
    } catch (error) {
      console.log("Error: " + error);
      throw error;
    }
  },

  async addImagesForProduct(id, images) {
    try {
      let result = await axios({
        method: "post",
        url: toolkit.setReactAppApi(`addImagesForProduct`),
        headers: { "content-type": "application/json" },
        data: {
          images: images,
          id: id,
        }

      })
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      console.log("Error: " + error);
      throw error;
    }
  },

  async addImagesForGallery(images) {
    try {
      let result = await axios({
        method: "post",
        url: toolkit.setReactAppApi(`addImagesForGallery`),
        headers: { "content-type": "application/json" },
        data: {
          images: images,
        }

      })
      if (result.status === 200) {
        return result.data
      }
    } catch (error) {
      console.log("Error: " + error);
      throw error;
    }
  }
}
export default toolkit;
