import React from 'react';
import "./regular-pages.css";

function RulesPage() {

    return (
        <div className="rules">
            <h1 className="rules" >Regulamin sklepu internetowego</h1>
            <h2><strong>Postanowienia ogólne</strong></h2>
            <p>Sklep GMO Meble (gmodesign.pl) obsługiwany jest przez firmę GMO Design Maciej Czyżewski, Ustronie Górne 52, 34-400 Nowy Targ, NIP: 9442068011, zwaną dalej GMO Design.</p>
            <h2><strong>Warunki dokonywania zakupów</strong></h2>
            <p>GMO Design prowadzi sprzedaż produktów za pomocą sklepu internetowego dostępnego pod adresem <a href="https://www.gmodesign.pl/shop">https://www.gmodesign.pl/shop</a></p>
            <h2><strong>Oferta i realizacja zakupów</strong></h2>
            <p>Podstawowym warunkiem realizacji dokonanych zakupów jest prawidłowe złożenie zamówienia na stronach sklepu lub mailowo, wybór formy płatności i sposobu dostawy wybranego produktu bądź produktów, oraz wpłata należności na konto GMO Design, jeśli wybrano inną formę dostawy niż odbiór osobisty.</p>
            <p>Numer konta : 92160014621893922780000001 (BNPParibas)</p>
            <p>Wszystkie ceny podawane w sklepie gmodesign.pl/sklep są cenami brutto wyrażonymi w złotych polskich (PLN).</p>
            <p>Uwaga!&nbsp;Oferty cenowe mają charakter informacyjny i nie stanowią oferty handlowej w rozumieniu art.66 §1 kodeksu cywilnego oraz innych właściwych przepisów prawnych.</p>
            <p>Ceny nie zawierają kosztu transportu, te widoczne są na stronie produktu, lub ustalane są indywidualnie w zależności od gabarytów produktu i sposobu dostarczenia towaru.</p>
            <p>Zlecenie zaczyna być realizowanie po zaksięgowaniu wpłaty. Towary wysyłane są możliwie najszybciej. &nbsp;Zlecenia ” na zamówienie” zostaną zrealizowane w ciągu 14-90 dni roboczych.</p>
            <p>Termin realizacji zlecenia “na zamówienie” podany jest w opisie produktu, lub ustalany jest indywidualnie.</p>
            <h2><strong>Reklamacje i gwarancje</strong></h2>
            <p>Produkty z oferty regularnej objęte są gwarancją 24 miesiące.</p>
            <p>W razie niedostatecznej jakości produktów, zamawiający ma prawo, żądać doprowadzenia reklamowanego produktu (-ów) do stanu zgodnego z umową poprzez nieodpłatną naprawę albo wymianę. Jeżeli GMO Design nie będzie w stanie wywiązać się z określonych żądań, klient ma prawo domagać się stosownego obniżenia ceny albo może odstąpić od umowy.</p>
            <h2><strong>Zwroty</strong></h2>
            <p>Zamawiający może odstąpić od umowy bez podania przyczyn, składając stosowne oświadczenie na piśmie lub pocztą elektroniczną w terminie dziesięciu dni, liczonym od dnia wydania rzeczy. Do zachowania tego terminu wystarczy wysłanie oświadczenia przed jego upływem. Do zwracanego towaru należy dołączyć dowód zakupu.</p>
            <p>UWAGA! Produkty wykonywane na zlecenie indywidualne (w tym produkty z oferty regularnej poddane modyfikacjom) nie podlegają zwrotom!</p>
            <h2><strong>Postanowienia końcowe</strong></h2>
            <p>GMO Design zobowiązuje się do ochrony danych osobowych zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE. Ochrona danych osobowych opisana jest w <a href="/polityka-prywatnosci/">Polityce Prywatności.&nbsp;</a></p>
            <p>Wypełnienie formularza z danymi Zamawiającego równoznaczne jest ze zgodą na przechowywanie oraz przetwarzanie podanych danych osobowych do celów realizacji i rozliczania transakcji.</p>
            <p>Dane osobowe Zamawiającego będą przetwarzane wyłącznie w celu realizacji złożonego zamówienia, w tym wystawienia faktury lub rachunku i prowadzenia sprawozdawczości finansowej.</p>
            <p>Dane te są poufne i nie będą ujawniane osobom trzecim.</p>
            <p>Klient ma prawo do wglądu do własnych danych osobowych oraz ich poprawek, a także usunięcia z bazy danych.</p>
            <p>W tym celu należy wysłać wiadomość z określoną dyspozycją na adres e-mail:&nbsp;<a href="mailto:%3Cspan%20id=">G</a><span><a href="mailto:GMO@GMO-Design.pl">MO@GMO-Design.pl</a></span>&nbsp;Dane dotyczące numeru karty płatniczej i rachunku bankowego, nie są znane GMO Design.</p>
            <p>Autoryzacja elektronicznych form płatności odbywa się na stronach internetowych odpowiednich banków, lub firm obsługujących płatności bezgotówkowe.</p>
        </div>


    );
}

export default RulesPage;