import React from 'react';
import { BrowserRouter, Routes, Route,  Navigate } from "react-router-dom";
import ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import Admin from './pages/admin/Admin';
import About from './pages/about/About';

const root = ReactDOMClient.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<App />}>
        </Route>
        <Route path="/contact" element={<App />}>
        </Route>
        <Route path="/about" element={<App />}>
        </Route>
        <Route path="/gallery" element={<App />}>
        </Route>
        <Route path="/polityka-prywatnosci" element={<App />}>
        </Route>
        <Route path="/regulamin" element={<App />}>
        </Route>
        <Route path="/zamowienie" element={<App />}>
        </Route>
        <Route path="/pielegnacja-drewna" element={<App />}>
        </Route>
        <Route path="/cart" element={<App />}>
        </Route>
        <Route path="/probki" element={<App />}>
        </Route>
        <Route path="/product" element={<App />}>
        </Route>
        <Route path="/about" element={<About />}>
        </Route>
        <Route path="/shop" element={<App />}>
        </Route>
        <Route path="/admin" element={<Admin />}>
        </Route>
        <Route path="/listProduct" element={<Admin />}>
        </Route>
        <Route path="/listCategories" element={<Admin />}>
        </Route>
        <Route path="/listCollection" element={<Admin />}>
        </Route>
        <Route path="/listDelivery" element={<Admin />}>
        </Route>
        <Route path="/listOptions" element={<Admin />}>
        </Route>
        <Route path="/listWood" element={<Admin />}>
        </Route>
        <Route path="/listGallery" element={<Admin />}>
        </Route>
        <Route path="/edit" element={<Admin />}>
        </Route>
        <Route
          path="*"
          element={<Navigate to="/home" />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

