import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Header from './Header';
import "./Navbar.css";
import toolkit from "../../utils";

/**
 * @component Navbar
 * @props - { object } -  config
 */
const Navbar = (props) => {
  const config = props.config;
  const [navBg, setNavBg] = useState(false);
  const [isContact, setIsContact] = useState(window.location.href.includes("contact"));
  const [isAbout, setIsAbout] = useState(window.location.href.includes("about"));
  let persistBlack = !window.location.href.includes("home")

  const changeNavBg = () => {
    window.scrollY >= 150 ? setNavBg(true) : setNavBg(false);
  }

  const animateBars = () => {
    let bars = document.getElementsByClassName("navbarsToAnimate");
    for (var i = 0; i < bars.length; i++) {
      bars[i].classList.toggle("change");
    }
    let menuToShow = document.getElementsByClassName("nav-sidebar");
    menuToShow[0].classList.toggle("show");

  }
  useEffect(() => {
    if (isContact) {
      const contactElement = document.getElementById('contact');
      if (contactElement) {
        const offset = contactElement.getBoundingClientRect().top;
        window.scrollTo({
          top: window.scrollY + offset + 200, // Adjust the value as needed
          behavior: 'smooth'
        });
      }
    }
  }, [isContact]);


  useEffect(() => {
    if (isAbout) {
      const contactElement = document.getElementById('about');
      if (contactElement) {
        contactElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isAbout])


  useEffect(() => {
    window.addEventListener('scroll', changeNavBg);
    return () => {
      window.removeEventListener('scroll', changeNavBg);
    }
  }, [])

  useEffect(() => {
    toolkit.updateCartQuantity();
  })

  return (

    <div className="navbar navbar-fixed-top">
      <Header {...(persistBlack || navBg ? { backgroundColor: '#000' } : {})} />
    
      <div className="navbar-collapse collapse" id="custom-collapse" aria-expanded="false" style={{ height: "1px" }} onClick={() => animateBars()} >
        <div className="barsContainer" onClick={() => animateBars()}>
          <div className="navbarsToAnimate bar1" onClick={() => animateBars()}></div>
          <div className="navbarsToAnimate bar2" onClick={() => animateBars()}></div>
          <div className="navbarsToAnimate bar3" onClick={() => animateBars()}></div>
        </div>
      </div>
      <nav className="nav-sidebar gmo-bar-block gmo-small navbar-color-on-scroll navbar-transparent" role="navigation">
      <img className="logo" src="media/logo-black-cutout.png"></img>
        {config.map((element, index) => (
          <a key={index} href={element.href} className={`gmo-bar-item gmo-button gmo-padding-large ${element.text}`}>
            <p>{element.text}</p>
          </a>
        ))}

        <div className="navbar-cart">
          <a href="/cart" title="Zobacz koszyk" className="cart-contents">
            <i className="fa fa-shopping-cart icon-basket gmo-xxlarge "></i>
            <span id="cart-item-number">0</span>
          </a>
        </div>
      </nav>
    </div>
  );

}

export default Navbar;
//propTypes for the component
Navbar.propTypes = {
  config: PropTypes.array.isRequired
};