import axios from "axios";
export function setEndpointReactAppApi(endpoint){
    let REACT_APP_API = "https://gmodesign.pl/"
    if (window.location.href.includes("localhost:3000")) {
      REACT_APP_API = "http://localhost:3001/";
    }
    return REACT_APP_API + endpoint
  }

  export async function isLoggedAsAdmin() {
    let isLogged = false;
    try {
      let result = await axios({
        method: "get",
        url: `${setEndpointReactAppApi("auth")}`,
        headers: { "content-type": "application/json" }
      })
      if (result.status === 200) {
        isLogged = true;
      }
    }
    catch (error) {
      console.log("Error: " + error);
    }
    return isLogged;
  }
